import React from 'react';
import SvgIcon from '@corecomponents/SvgIcon';
const UpdatedLoadingIcon = (props) => {
    return (<SvgIcon viewBox="0 0 775.200000 777.600000" {...props}>
            <g id="loading-spinner-petal-container" style={{ isolation: 'isolate' }}>
                <g id="loading-spinner-petal1">
                    <g id="loading-spinner-petal1-rotate" transform="rotate(30 -69 634)">
                        <g id="loading-spinner-petal1-scale" transform="scale(.3)">
                            <path id="loading-spinner-petal1-show" fill="#3777CB" fillOpacity="0" stroke="none" strokeWidth="1" d="M507 19a388 388 0 00-375 77v1c-43 37-77 84-100 136a682 682 0 00434-91c43-25 83-55 118-89-24-14-50-25-77-34z" opacity=".7" transform="translate(-308 -119)"/>
                        </g>
                    </g>
                </g>
                <g id="loading-spinner-petal2">
                    <g id="loading-spinner-petal2-translate" transform="translate(255 441)">
                        <g id="loading-spinner-petal2-rotate" transform="rotate(30)">
                            <g id="loading-spinner-petal2-scale" transform="scale(.3)">
                                <path id="loading-spinner-petal2-show" fill="#3777CB" fillOpacity="0" stroke="none" strokeWidth="1" d="M74 161a386 386 0 00-42 380c22 51 55 99 99 137a684 684 0 0049-440h-1c-11-48-26-95-47-140-22 19-41 39-58 63z" opacity=".7"/>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="loading-spinner-petal3">
                    <g id="loading-spinner-petal3-translate" transform="translate(453 369)">
                        <g id="loading-spinner-petal3-rotate" transform="rotate(30)">
                            <g id="loading-spinner-petal3-scale" transform="scale(.3)">
                                <path id="loading-spinner-petal3-show" fill="#3777CB" fillOpacity="0" stroke="none" strokeWidth="1" d="M76 618a389 389 0 00348 158h-1c55-5 110-22 161-52a681 681 0 00-403-182c-48-4-98-4-147 3 11 26 24 50 42 73z" opacity=".7"/>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="loading-spinner-petal4">
                    <g id="loading-spinner-petal4-translate" transform="translate(532 222)">
                        <g id="loading-spinner-petal4-rotate" transform="rotate(30)">
                            <g id="loading-spinner-petal4-scale" transform="scale(.3)">
                                <path id="loading-spinner-petal4-show" fill="#3777CB" fillOpacity="0" stroke="none" strokeWidth="1" d="M508 758a382 382 0 00257-283c13-56 13-113 0-169a667 667 0 00-298 329c-19 45-33 93-42 141 27-4 55-9 83-18z" opacity=".7"/>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="loading-spinner-petal5">
                    <g id="loading-spinner-petal5-translate" transform="translate(398 -10)">
                        <g id="loading-spinner-petal5-rotate" transform="rotate(30)">
                            <g id="loading-spinner-petal5-scale" transform="scale(.3)">
                                <path id="loading-spinner-petal5-show" fill="#3777CB" fillOpacity="0" stroke="none" strokeWidth="1" d="M775 386A389 389 0 00586 53C537 24 482 6 424 0a659 659 0 00220 384c37 33 78 62 121 85 6-27 10-54 10-83z" opacity=".7"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>);
};
UpdatedLoadingIcon.displayName = 'UpdatedLoadingIcon';
export default UpdatedLoadingIcon;
