import { createIdentityManager, onSignin } from '@services/identityManager';
import configService from '@services/config.service';
import LoadingOverlay from '@corecomponents/Loading/LoadingOverlay';
import PropTypes from 'prop-types';
import React from 'react';
import LocalStorageUtility from '../common/utility/localStorageUtility';
import { identityRoutes } from '../routes/identityRoutes';
import TravelPortalSourceFiles from '../ExternalApps/TravelPortal/asset-manifest.json';
import ProfileManagementPortalSourceFiles from '../ExternalApps/ProfileManagementPortal/asset-manifest.json';
import './Microfrontend.scss';

const getJSFiles = (host, manifestValues, mainValue, scriptId) => {
    return manifestValues
        .filter((value) => value.endsWith('js'))
        .reduce((sum, value) => {
            sum.push(
                // If the script already exists, don't add it again
                !document.getElementById(value) || !document.getElementById(scriptId)
                    ? new Promise((resolve) => {
                          const path = `${host}${value}`;
                          const script = document.createElement('script');

                          script.id = value === mainValue ? scriptId : value;
                          script.onload = () => resolve();
                          script.src = path;
                          document.body.after(script);
                      })
                    : undefined
            );
            return sum;
        }, []);
};

const getCSSFiles = (host, manifestValues, linkId) => {
    return manifestValues
        .filter((value) => value.endsWith('css'))
        .reduce((sum, value) => {
            sum.push(
                new Promise((resolve) => {
                    const path = `${host}${value}`;
                    const link = document.createElement('link');

                    link.className = linkId;
                    link.onload = () => resolve();
                    link.rel = 'stylesheet';
                    link.type = 'text/css';
                    link.href = path;
                    document.head.appendChild(link);
                })
            );
            return sum;
        }, []);
};

const getManifestFile = (name) =>
    ({
        TravelPortal: TravelPortalSourceFiles,
        ProfileManagementPortal: ProfileManagementPortalSourceFiles,
    }[name]);

class MicroFrontend extends React.Component {
    constructor(props) {
        super(props);
    }

    // Link to more info about async componentDidMount: https://stackoverflow.com/questions/47970276/is-using-async-componentdidmount-good
    async componentDidMount() {
        const { name, host, history, localization, theme, portalConfiguration } = this.props;
        const environment = LocalStorageUtility.getValue('environment');

        const {
            IdentityServerBaseUrl = '',
            IdentityServerRedirectUrl = '',
            IdentityServerClientId = '',
            IdentityServerClientScope = '',
            SilentAuthorization = false,
            SessionMonitoring = false,
            SelfRegistrationBaseUrl = '',
        } = configService || {};

        const { organizationUuid, divisionUuid, id } = portalConfiguration;

        const identityManager = createIdentityManager({
            identityServerBaseUrl: IdentityServerBaseUrl,
            identityServerRedirectUrl: IdentityServerRedirectUrl,
            clientId: IdentityServerClientId,
            scope: IdentityServerClientScope,
            enableSilentAuthorization: SilentAuthorization,
            enableSessionMonitoring: SessionMonitoring,
            origin: window.location.toString().replace('/contact', ''),

            organizationUuid: organizationUuid,
            divisionUuid: divisionUuid,
            portalId: id,

            selfRegBaseUrl: SelfRegistrationBaseUrl,
            signinCallbackPath: identityRoutes.signinCallback,
            renewCallbackPath: identityRoutes.accessTokenRenewCallback,
            signoutCallbackPath: identityRoutes.signoutCallback,

            onSignin,
        });

        if (!name) {
            return null;
        }

        const scriptId = `micro-frontend-script-${name}`;
        const linkId = `micro-frontend-link-${name}`;

        const renderMicroFrontend = async () => {
            window[`render${name}`] &&
                window[`render${name}`]({
                    containerId: `${name}-container`,
                    history: history,
                    signOutCallback: identityManager.signoutRedirect,
                    localization: localization,
                    configuration: {
                        portalId: portalConfiguration.id,
                        organizationUuid: portalConfiguration.organizationUuid,
                        divisionUuid: portalConfiguration.divisionUuid,
                        portalUrl: portalConfiguration.portalUrl,
                    },
                    theme: {
                        name: theme.name,
                        logo: theme.logo, // Route to the actual theme logo
                        title: theme.title,
                    },
                });

            // update all image sources to show apps assets
            const observer = new MutationObserver(() => {
                const images = document.getElementById(`${name}-container`)?.getElementsByTagName('img');

                if (images?.length) {
                    Array.from(images).forEach((image) => {
                        // Checking if the image source is mostly the same as the host, to not change external image sources
                        if (
                            !image.src.startsWith(host) &&
                            image.src.includes(new URL(host).host) &&
                            !image.src.startsWith('data') &&
                            !image.src.startsWith('file')
                        ) {
                            const path = image.src.startsWith('http') ? new URL(image.src).pathname : image.src;

                            image.src = `${host}/${path}`;
                        }
                    });
                }
            });

            observer.observe(document.getElementById(`${name}-container`) || document.body, {
                childList: true,
                subtree: true,
            });
        };

        if (document.getElementById(scriptId)) {
            renderMicroFrontend();
            return;
        }

        const noCacheHeaders = new Headers();
        noCacheHeaders.append('pragma', 'no-cache');
        noCacheHeaders.append('cache-control', 'no-cache');

        const manifest =
            environment !== 'Development'
                ? await fetch(`/${name}/asset-manifest.json`, { cache: 'no-store', headers: noCacheHeaders }).then(
                      (response) => response.json()
                  )
                : getManifestFile(name);

        const manifestKeys = Object.keys(manifest['files'] || {}).length
            ? Object.keys(manifest['files'])
            : Object.keys(manifest);

        let manifestValues = Object.values(manifest['files'] || {}).length
            ? Object.values(manifest['files'])
            : Object.values(manifest);

        // Append the public path to the asset manifest values when in dev so it uses the SPA proxy
        manifestValues =
            environment === 'Development' ? manifestValues.map((value) => `/public${value}`) : manifestValues;

        const [mainKey] = manifestKeys.filter((key) => key === 'main' || key === 'main.js');

        const mainValue = manifest[mainKey] || manifest['files'][mainKey];

        const promises = [
            ...getJSFiles(host, manifestValues, mainValue, scriptId),
            ...getCSSFiles(host, manifestValues, linkId),
        ];

        Promise.allSettled(promises).then(() => {
            renderMicroFrontend();
        });
    }

     componentDidUpdate(prevProps) {
        const { name, host, history, localization, theme, portalConfiguration } = this.props;

        if (prevProps.localization.messages['export.cancelled'] !== localization.messages['export.cancelled']) {
            const {
                IdentityServerBaseUrl = '',
                IdentityServerRedirectUrl = '',
                IdentityServerClientId = '',
                IdentityServerClientScope = '',
                SilentAuthorization = false,
                SessionMonitoring = false,
                SelfRegistrationBaseUrl = '',
            } = configService || {};

            const { organizationUuid, divisionUuid, id } = portalConfiguration;

            const identityManager = createIdentityManager({
                identityServerBaseUrl: IdentityServerBaseUrl,
                identityServerRedirectUrl: IdentityServerRedirectUrl,
                clientId: IdentityServerClientId,
                scope: IdentityServerClientScope,
                enableSilentAuthorization: SilentAuthorization,
                enableSessionMonitoring: SessionMonitoring,
                origin: window.location.toString().replace('/contact', ''),

                organizationUuid: organizationUuid,
                divisionUuid: divisionUuid,
                portalId: id,

                selfRegBaseUrl: SelfRegistrationBaseUrl,
                signinCallbackPath: identityRoutes.signinCallback,
                renewCallbackPath: identityRoutes.accessTokenRenewCallback,
                signoutCallbackPath: identityRoutes.signoutCallback,

                onSignin,
            });

            if (!name) {
                return null;
            }

            const renderMicroFrontend = () => {
                window[`render${name}`] &&
                    window[`render${name}`]({
                        containerId: `${name}-container`,
                        history: history,
                        signOutCallback: identityManager.signoutRedirect,
                        localization: localization,
                        configuration: {
                            portalId: portalConfiguration.id,
                            organizationUuid: portalConfiguration.organizationUuid,
                            divisionUuid: portalConfiguration.divisionUuid,
                            portalUrl: portalConfiguration.portalUrl,
                        },
                        theme: {
                            name: theme.name,
                            logo: theme.logo,
                            title: theme.title,
                        },
                    });

                // update all image sources to show apps assets
                const observer = new MutationObserver(() => {
                    const images = document.getElementById(`${name}-container`)?.getElementsByTagName('img');

                    if (images?.length) {
                        Array.from(images).forEach((image) => {
                            // Checking if the image source is mostly the same as the host, to not change external image sources
                            if (
                                !image.src.startsWith(host) &&
                                image.src.includes(new URL(host).host) &&
                                !image.src.startsWith('data') &&
                                !image.src.startsWith('file')
                            ) {
                                const path = image.src.startsWith('http') ? new URL(image.src).pathname : image.src;

                                image.src = `${host}/${path}`;
                            }
                        });
                    }
                });

                observer.observe(document.getElementById(`${name}-container`) || document.body, {
                    childList: true,
                    subtree: true,
                });
            };

            renderMicroFrontend();
        }
    }

    componentWillUnmount() {
        const { name } = this.props;

        if (!name) {
            return;
        }

        // Remove all app style links that were added
        document.querySelectorAll(`.micro-frontend-link-${name}`).forEach((element) => element.remove());

        // Remove main app script that was added
        document.querySelectorAll(`#micro-frontend-script-${name}`).forEach((element) => element.remove());

        window[`unmount${name}`] && window[`unmount${name}`](`${name}-container`);
    }

    render() {
        const { name } = this.props;

        return (
            <main id={`${name}-container`}>
                <LoadingOverlay />
            </main>
        );
    }
}

MicroFrontend.propTypes = {
    history: PropTypes.object,
    host: PropTypes.string,
    name: PropTypes.string,
    localization: PropTypes.object,
};

export default MicroFrontend;
