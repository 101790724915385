import {
    useTopic,
    viewWeatherAndEvents,
    weatherAndEventsAustralia,
    weatherAndEventsCanada,
    weatherAndEventsEurope,
    weatherAndEventsGlobal,
    weatherAndEventsJapan,
    weatherAndEventsUSA
} from 'common/constants/permissions';

const canViewTopics = permissions => !!permissions[useTopic];

const canViewWeatherAndEvents = permissions =>
    [
        weatherAndEventsAustralia,
        weatherAndEventsCanada,
        weatherAndEventsEurope,
        weatherAndEventsJapan,
        weatherAndEventsUSA,
        weatherAndEventsGlobal
    ].some(x => permissions.hasOwnProperty(x)) && permissions.hasOwnProperty(viewWeatherAndEvents);

export default {
    canViewTopics,
    canViewWeatherAndEvents
};
