const routeCheck = (url = '', appName, portalUrl) => {
    let route = url?.pathname ?? url;

    const params = route.match(/(:\w+)/);

    if (url?.pathname) {
        route = route.replace(params[0], portalUrl);

        return route;
    }

    route = params?.length ? route.replace(params[0], portalUrl) : route;

    // If you have a match.url, it's entirely possible that the url already contains /appName here...
    const appURL = route.toLowerCase().includes(appName.toLowerCase());
    const relativePath = route.includes('./');

    if (!appURL && !relativePath) {
        route = url[0] == '/' ? `/${portalUrl}/${appName}${route}` : `/${portalUrl}/${appName}/${route}`;
    }

    return route;
};

export const updatedPush =
    (push, appName, portalUrl) =>
    (url, { ...optionalProps } = {}) => {
        return Object.keys(optionalProps).length
            ? push(routeCheck(url, appName, portalUrl), optionalProps)
            : push(routeCheck(url, appName, portalUrl));
    };
