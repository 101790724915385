import React from 'react';
import getClassName from '@utils/getClassName';
import styles from './PopoverHeader.module.scss';
import IconButton from '@corecomponents/IconButton';
import TimesIcon from '@icons/Times';
import keyCodes from '../core-utils/keyCodes';
const PopoverHeader = ({ className, children, iconLabel, onClose = () => null, handleClose = () => null, }) => {
    const [rootClass, getChildClass] = getClassName({
        rootClass: 'popoverHeader',
        className,
        styles,
    });
    const handleKeyDown = (e) => {
        if (e.keyCode === keyCodes.ENTER_KEY) {
            e.preventDefault();
            onClose(e);
        }
    };
    const handleOnClose = (e) => {
        onClose(e);
        handleClose(e);
    };
    return (<div className={rootClass}>
            <div className={getChildClass('title')}>{children}</div>
            <IconButton className={getChildClass('action')} size="xs" onClick={handleOnClose} onKeyDown={handleKeyDown} tabIndex={0} aria-label={iconLabel}>
                <TimesIcon color="secondary" style={{ height: '0.7rem', width: '0.7rem', fontWeight: '400', color: '#565858' }}/>
            </IconButton>
        </div>);
};
PopoverHeader.displayName = 'PopoverHeader';
export default PopoverHeader;
