import generate from '@babel/generator';
import * as t from '@babel/types';
import { parse } from 'messageformat-parser';
//TODO: CONT-1213 Clean up real-time catalog compilation
const INVALID_OBJECT_KEY_REGEX = /^(\d+[a-zA-Z]|[a-zA-Z]+\d)(\d|[a-zA-Z])*/;
const isString = (s) => typeof s === 'string';
// Takes the single message and tokenizes it into an AST
function compile(message) {
    let tokens = [];
    try {
        tokens = parse(message);
    }
    catch (e) { }
    const ast = processTokens(tokens);
    if (isString(ast))
        return t.stringLiteral(ast);
    return ast;
}
function processTokens(tokens) {
    // Shortcut - if the message doesn't include any formatting,
    // simply join all string chunks into one message
    if (!tokens.filter((token) => !isString(token)).length) {
        return tokens.join('');
    }
    return t.arrayExpression(tokens.map((token) => {
        if (isString(token)) {
            return t.stringLiteral(token);
            // # in plural case
        }
        else if (token.type === 'octothorpe') {
            return t.stringLiteral('#');
            // simple argument
        }
        else if (token.type === 'argument') {
            return t.arrayExpression([t.stringLiteral(token.arg)]);
            // argument with custom format (date, number)
        }
        else if (token.type === 'function') {
            const params = [t.stringLiteral(token.arg), t.stringLiteral(token.key)];
            const format = token.param && token.param.tokens[0];
            if (format) {
                params.push(t.stringLiteral(format.trim()));
            }
            return t.arrayExpression(params);
        }
        // complex argument with cases
        const formatProps = [];
        if (token.offset) {
            formatProps.push(t.objectProperty(t.identifier('offset'), t.numericLiteral(parseInt(token.offset))));
        }
        token.cases.forEach((item) => {
            const inlineTokens = processTokens(item.tokens);
            formatProps.push(t.objectProperty(
            // if starts with number must be wrapped with quotes
            INVALID_OBJECT_KEY_REGEX.test(item.key) ? t.stringLiteral(item.key) : t.identifier(item.key), isString(inlineTokens) ? t.stringLiteral(inlineTokens) : inlineTokens));
        });
        const params = [t.stringLiteral(token.arg), t.stringLiteral(token.type), t.objectExpression(formatProps)];
        return t.arrayExpression(params);
    }));
}
// Used for each message in the AST, will determine whether it's a string or array
function compileSingleKey(key, translation) {
    return t.objectProperty(t.stringLiteral(key), compile(translation));
}
export const createCompiledCatalog = (messages, options) => {
    const { strict = false } = options;
    const compiledMessages = Object.keys(messages).map((key) => {
        // Don't use `key` as a fallback translation in strict mode.
        let translation = (messages[key] || (!strict ? key : ''));
        return compileSingleKey(key, translation);
    });
    const ast = t.objectExpression(compiledMessages);
    const code = generate(ast, {
        minified: true,
        jsescOption: {
            minimal: true,
        },
    }).code;
    return code;
};
