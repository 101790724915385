import React, { createContext, useContext } from 'react';
import getApiClient from '@utils/getApiClient';
import { useQuery } from '@tanstack/react-query';
const DEFAULT_CONTEXT = {
    themeLogo: '/dist/horizontal-logo.svg',
    themeColor: 'blue',
    themeTitle: 'Default',
    isLoading: false,
};
const [portalUrl] = window.location.pathname.split('/').filter(Boolean);
const THEME_KEY = `theme_${portalUrl}`;
const client = getApiClient();
const ThemeContext = createContext(DEFAULT_CONTEXT);
export const useThemeContext = () => useContext(ThemeContext);
const ThemeProvider = ({ children }) => {
    const { data, isFetching } = useQuery([THEME_KEY], async () => {
        const { data = DEFAULT_CONTEXT } = await client.get(`api/portal/${portalUrl}/theme`);
        return data?.data;
    }, {
        staleTime: Infinity,
        onError: (error) => {
            console.error('GET_PORTAL_THEME', error);
        },
        placeholderData: DEFAULT_CONTEXT,
        retry: 3,
        enabled: portalUrl?.toLocaleLowerCase() !== 'identity',
    });
    const serverConfig = data || DEFAULT_CONTEXT;
    const values = {
        themeLogo: serverConfig.themeLogo,
        themeColor: serverConfig.themeColor,
        themeTitle: serverConfig.themeTitle,
        isLoading: isFetching,
    };
    return <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>;
};
export default ThemeProvider;
