import React from 'react';
import getClassName from '@utils/getClassName';
import LoadingSpinner from './LoadingSpinner';
import Typography from '@corecomponents/Typography/Typography';
import styles from './LoadingOverlay.module.scss';
const LoadingOverlay = ({ className, description, logoType, message, spinner, spinnerSize = '4x', title, overlay = true, }) => {
    const [rootClass, getChildClass] = getClassName({
        rootClass: 'loadingOverlay',
        className,
        styles,
        modifiers: {
            overlay,
        },
    });
    return (<div className={rootClass}>
            {spinner || <LoadingSpinner color="primary" logoType={logoType} size={spinnerSize}/>}
            {message || (<>
                    {title && (<Typography variant="p14" className={getChildClass('title')}>
                            {title}
                        </Typography>)}
                    {description && (<Typography variant="p12" className={getChildClass('description')}>
                            {description}
                        </Typography>)}
                </>)}
        </div>);
};
LoadingOverlay.displayName = 'LoadingOverlay';
export default LoadingOverlay;
