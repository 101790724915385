import React from 'react';
import styles from './SvgIcon.module.scss';
import getClassName from '@utils/getClassName';
const SvgIcon = ({ children, className, focusable = 'false', title, viewBox = '0 0 16 16', ...svgProps }) => {
    const [rootClass] = getClassName({
        styles,
        rootClass: 'svgIcon',
        className,
    });
    return (<svg {...svgProps} xmlns="http://www.w3.org/2000/svg" className={rootClass} focusable={focusable} viewBox={viewBox} aria-hidden="true">
            {title && <title>{title}</title>}
            {children}
        </svg>);
};
export default SvgIcon;
