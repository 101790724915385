import configService from '@services/config.service';
import CookieService from './cookie.service';
import namespaceService from './namespace.service';
import getApiClient from '@utils/getApiClient';
import { identityManager } from './identityManager';
const AuthService = {
    async signOut(mir3Logout = true, clientErrorCode = undefined) {
        if (mir3Logout) {
            const apiClient = getApiClient({ baseURL: configService.TahoeUrl });
            const cookieService = new CookieService();
            const domain = namespaceService.getDomainWithoutNamespace();
            // the following cookies are set to a domain without a namespace prefix,
            // that's why the same domain should be used for deletion
            cookieService.deleteCookie('loggedInNamespace', domain);
            cookieService.deleteCookie('originURL', domain);
            //Invalidate all open sessions in mir3 based on user authorization token
            apiClient.post('/actions/session/invalidate');
        }
        await identityManager.signoutRedirect();
    },
};
export default AuthService;
