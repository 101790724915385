export const DEFAULT_FULL_PORTAL_DETAIL = {
    id: null,
    organizationId: null,
    name: null,
    editableUrl: {
        type: null,
        urlPart: null,
    },
    brandingTheme: null,
    createdAt: null,
    updatedAt: null,
    confirmCustomPolicies: false,
    isHelpEnabled: false,
    helpTextValues: [],
    logoFileId: null,
    logoFileNamespace: null,
    defaultCountryCode: 1,
    isSignUpEnabled: true,
    isAccessPinEnabled: false,
    isPublicEnrollmentEnabled: false,
    isTravelEnabled: false,
    accessPin: null,
    isEmailDomainWhiteListEnabled: false,
    emailDomainWhiteList: [],
    contactFields: {
        general: [],
        devices: [
            {
                deviceLabelId: null,
                id: null,
                isRequired: false,
                isLocked: false,
                displayName: null,
                helpText: null,
            },
        ],
        locations: [],
        groups: [],
    },
    subscriptions: [],
    divisions: [],
    portalLogoUrl: null,
    isUserSelfDeactivationEnabled: false,
    accountExpiration: {
        isEnabled: false,
        expirationNotificationSchedule: {
            daysBeforeExpiration: null,
        },
        removalSchedule: {
            type: null,
            dayOfMonth: null,
        },
    },
    divisionMetadata: undefined,
};
