import React from 'react';
import ThemeProvider from './ThemeProvider';
import LinguiProvider from './LinguiProvider';
import ReactIntlProvider from './ReactIntlProvider';
import LocaleServiceProvider from './LanguageServiceProvider/LanguageServiceProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CustomPoliciesProvider from './CustomPoliciesProvider';
import CustomHelpTextProvider from './CustomHelpTextProvider';
const queryClient = new QueryClient();
const AppProviders = ({ children }) => {
    return (<LinguiProvider>
            <ReactIntlProvider>
                <QueryClientProvider client={queryClient}>
                    <LocaleServiceProvider>
                        <CustomPoliciesProvider>
                            <CustomHelpTextProvider>
                                <ThemeProvider>{children}</ThemeProvider>
                            </CustomHelpTextProvider>
                        </CustomPoliciesProvider>
                    </LocaleServiceProvider>
                </QueryClientProvider>
            </ReactIntlProvider>
        </LinguiProvider>);
};
export default AppProviders;
