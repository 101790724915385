const bind = (element, event, callback, options) => {
    if (element && element.addEventListener) {
        element.addEventListener(event, callback, options);
    }
};
const unbind = (element, event, callback, options) => {
    if (element && element.removeEventListener) {
        element.removeEventListener(event, callback, options);
    }
};
export default {
    bind,
    unbind,
};
