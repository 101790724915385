import React from 'react';
import styles from './Footer.module.scss';
import { useThemeContext } from '@context/ThemeProvider';
import Logo from '@assets/images/horizontal-logo.svg';
import PermissionService from '@services/permission.service';
import { t } from '@lingui/macro';
import Link from '@core/Link';
import { policiesTitle, PolicyDisplayOption, useCustomPoliciesContext } from '@context/CustomPoliciesProvider';
const [portalUrl] = window.location.pathname.split('/').filter(Boolean);
const Footer = () => {
    const { canViewWTPCustomer } = new PermissionService().permissions;
    const { themeLogo } = useThemeContext();
    const { customPolicies, isCustomPoliciesLoading } = useCustomPoliciesContext();
    const PoliciesBlock = () => {
        return (<div className={styles.footer__policies}>
                {!isCustomPoliciesLoading &&
                customPolicies?.map(({ id, policyDisplayOption, policyType }) => {
                    const policyTitle = policiesTitle.filter((policies) => policies.id === policyType)[0]?.value;
                    if (policyDisplayOption === PolicyDisplayOption.DO_NOT_DISPLAY) {
                        return null;
                    }
                    return (<Link to={`/${portalUrl}/policies/${policyType}`} target="_blank" rel="noopener noreferrer" key={id} className={styles.footer__policyLink}>
                                {policyTitle}
                            </Link>);
                })}
            </div>);
    };
    return (<footer className={styles.footer}>
            <img src={themeLogo} alt="Logo" className={styles.footer__themeLogo}/>
            {PoliciesBlock()}
            <div className={styles.footer__right}>
                <div className={styles.footer__poweredBy}>
                    Powered by <img src={Logo} className={styles.footer__logo}/>
                </div>

                <div className={styles.footer__terms}>
                    <div>{t `Your contact information is governed under`}</div>
                    {canViewWTPCustomer() ? (<a href="https://worldtravelprotection.com/wp-content/uploads/2022/07/Global-WTP-Privacy-Policy-June-2022.pdf" target="_blank" rel="noopener noreferrer" className={styles.terms}>
                            {t `WTP's Privacy Policy and Terms of Service`}
                        </a>) : (<a href="https://www.onsolve.com/privacy-statement/" target="_blank" rel="noopener noreferrer" className={styles.footer__link}>
                            {t `ONSOLVE's Terms of Service and Privacy Policy`}
                        </a>)}
                </div>
            </div>
        </footer>);
};
export default Footer;
