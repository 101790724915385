import React, { useCallback, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import styles from './Popover.module.scss';
import getClassName from '@utils/getClassName';
import keyCodes from '../core-utils/keyCodes';
import domUtils from '../core-utils/domUtils';
import eventsUtils from '../core-utils/eventsUtils';
import popoverManager from './popoverManager';
const Popover = ({ children, className, id, innerRef, isOpen = false, onClose = () => null, onOpen = () => null, position = 'bottom-left', shouldFocus, size = 'md', target, trigger, withTargetCacheReset = false, ...otherProps }) => {
    const popoverId = `popover_${id || uuid()}`;
    const elementRef = useRef(null);
    const isOpenPrev = useRef(isOpen);
    const handleClose = useCallback((e) => {
        onClose(e);
    }, [onClose]);
    const handleKeyUp = useCallback((e) => {
        const charCode = domUtils.getCharCode(e);
        handleClose(e);
        if (charCode === keyCodes.ESC) {
            const result = domUtils.isInDOMSubtree(e.target, elementRef.current);
            if (result) {
                handleClose(e);
            }
        }
    }, [handleClose]);
    const handleDocumentClick = useCallback((e) => {
        if (target !== e.target.id && popoverManager.canClose(popoverId, e.target)) {
            handleClose(e);
        }
    }, [handleClose, popoverId, target]);
    const registerPopup = useCallback((isOpen, shouldFocus) => {
        if (isOpen) {
            const element = elementRef.current;
            popoverManager.register(popoverId, element);
            shouldFocus && domUtils.setFocus(element?.querySelector('button'));
            eventsUtils.bind(document, 'click', handleDocumentClick, true);
        }
        else {
            popoverManager.unregister(popoverId);
            eventsUtils.unbind(elementRef.current, 'keydown', handleTab);
            eventsUtils.unbind(document, 'click', handleDocumentClick, true);
            eventsUtils.unbind(window, 'keyup', handleKeyUp);
        }
    }, [handleDocumentClick, handleKeyUp, popoverId]);
    // TODO: This is the beginnings for auto-positioning
    // useEffect(() => {
    //     if (isOpen) {
    //         const rect = elementRef.current.getBoundingClientRect();
    //         const win = elementRef.current.ownerDocument.defaultView;
    //         console.log('POPOVERPOSITIONS', {
    //             top: rect.top + win.pageYOffset,
    //             left: rect.left + win.pageXOffset,
    //         });
    //     }
    // }, [isOpen]);
    useEffect(() => {
        if (isOpenPrev.current !== isOpen) {
            if (isOpen) {
                elementRef.current.removeAttribute('aria-hidden');
                eventsUtils.bind(elementRef.current, 'keydown', handleTab);
                eventsUtils.bind(window, 'keyup', handleKeyUp);
                onOpen();
            }
            else {
                elementRef.current.setAttribute('aria-hidden', true);
                eventsUtils.unbind(elementRef.current, 'keydown', handleTab);
                eventsUtils.unbind(window, 'keyup', handleKeyUp);
            }
            registerPopup(isOpen, shouldFocus);
            isOpenPrev.current = isOpen;
        }
        return () => registerPopup(false, shouldFocus);
    }, [isOpen, handleKeyUp, onOpen, registerPopup, shouldFocus]);
    const handleTab = (e) => {
        const charCode = domUtils.getCharCode(e);
        if (charCode === keyCodes.TAB) {
            const focusableChildren = elementRef.current.querySelectorAll(domUtils.getFocusableElements().join(', '));
            const totalFocusable = focusableChildren.length;
            let focusedIndex = 0;
            for (let i = 0; i < totalFocusable; i++) {
                if (focusableChildren[i] === document.activeElement) {
                    focusedIndex = i;
                    break;
                }
            }
            if (e.shiftKey && focusedIndex === 0) {
                e.preventDefault();
                domUtils.setFocus(focusableChildren[totalFocusable - 1]);
            }
            else if (!e.shiftKey && focusedIndex === totalFocusable - 1) {
                e.preventDefault();
                domUtils.setFocus(focusableChildren[0]);
            }
        }
    };
    const [rootClass, getChildClass] = getClassName({
        rootClass: 'popover',
        className,
        styles,
        modifiers: {
            [position]: !!position,
            [size]: !!size,
        },
    });
    return (<div {...otherProps} tabIndex={-1} className={rootClass} ref={elementRef} key={withTargetCacheReset ? uuid() : id}>
            {isOpen && <div className={getChildClass('container')}>{children}</div>}
        </div>);
};
Popover.displayName = 'Popover';
export default Popover;
