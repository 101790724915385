import configService from '@services/config.service';
import JwtService from './jwt.service';
import getApiClient from '@utils/getApiClient';
const getIdentityContext = async () => {
    const apiClient = getApiClient({ baseURL: configService.IdentityServerBaseUrl });
    const jwtService = new JwtService();
    const { idctxt } = jwtService.getPermissionsClaims();
    if (!idctxt) {
        return { attributes: {} };
    }
    try {
        const response = await apiClient.get(`/api/v1/IdentityContext/${idctxt}`);
        return response?.data ?? { attributes: { 'security:uiSessionTimeoutInSeconds': 3600 } };
    }
    catch (error) {
        console.error(error);
        return { attributes: {} };
    }
};
const identityService = {
    getIdentityContext,
};
export default identityService;
