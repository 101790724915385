import React from 'react';
import SvgIcon from '@corecomponents/SvgIcon';
const DotsSpinner = (props) => (<SvgIcon {...props}>
        <g>
            <circle id="circle1" cx="8" cy="2" r="1.5"/>
            <circle id="circle2" cx="12.243" cy="3.757" r="1.5"/>
            <circle id="circle3" cx="14" cy="8" r="1.5"/>
            <circle id="circle4" cx="12.243" cy="12.243" r="1.5"/>
            <circle id="circle5" cx="8" cy="14" r="1.5"/>
            <circle id="circle6" cx="3.757" cy="12.243" r="1.5"/>
            <circle id="circle7" cx="2" cy="8" r="1.5"/>
            <circle id="circle8" cx="3.757" cy="3.757" r="1.5"/>
        </g>
    </SvgIcon>);
DotsSpinner.displayName = 'DotsSpinnerIcon';
export default DotsSpinner;
