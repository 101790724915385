import keyCodes from './keyCodes';
const OVERFLOW_REGEXP = /auto|scroll/;
const FOCUSABLE_ELEMENTS = [
    'a[href]',
    'area[href]',
    'input:not([disabled]):not([type=hidden])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    'button:not([disabled])',
    'object',
    'embed',
    '[tabindex]:not(.modal):not([tabindex^="-"])',
    'audio[controls]',
    'video[controls]',
    '[contenteditable]:not([contenteditable="false"])',
];
const VIEWPORT_WIDTH_OFFSET = 15;
let scrollbarWidth = null;
export const getViewport = () => {
    const { innerWidth, innerHeight, scrollY } = window || { innerWidth: 0, innerHeight: 0, scrollY: 0 };
    const { clientWidth, clientHeight, scrollTop } = document.documentElement || {
        clientWidth: 0,
        clientHeight: 0,
        scrollTop: 0,
    };
    const { clientWidth: clientWidthBody, clientHeight: clientHeightBody, scrollTop: scrollTopBody, } = document.body || { clientWidth: 0, clientHeight: 0, scrollTop: 0 };
    /**
     * scrollTopBody only works in quirk mode
     */
    return {
        width: innerWidth || clientWidth || clientWidthBody,
        height: innerHeight || clientHeight || clientHeightBody,
        scroll: scrollY || scrollTop || scrollTopBody,
    };
};
export const calculateElementPosition = (position, element, target) => {
    const { offsetWidth: elementWidth, offsetHeight: elementHeight } = element;
    const { offsetWidth: targetWidth, offsetHeight: targetHeight } = target;
    const { top: targetTop, bottom: targetBottom, left: targetLeft, right: targetRight, } = target.getBoundingClientRect();
    const { height: viewportHeight, width: viewportWidth, scroll: viewPortScroll } = getViewport();
    const rtl = isRtl();
    let top, left, width = Math.max(elementWidth, targetWidth);
    if (position === 'left' || position === 'center') {
        left = rtl ? viewportWidth - targetRight : targetLeft;
    }
    else {
        left = rtl ? targetLeft : targetRight - width;
        if (left < 0) {
            left = width - targetRight;
        }
    }
    if (targetTop + targetHeight + elementHeight > viewportHeight - viewPortScroll) {
        top = targetTop - elementHeight;
    }
    else {
        top = targetBottom;
    }
    const direction = rtl && (position === 'left' || position === 'center') ? 'right' : 'left';
    if (position === 'center') {
        element.style.width = targetWidth + 'px';
    }
    else if (position === 'left') {
        element.style['min-width'] = targetWidth + 'px';
        const offset = getScrollbarWidth() + VIEWPORT_WIDTH_OFFSET;
        if (rtl && targetLeft >= offset) {
            width = targetWidth + (targetLeft - offset);
        }
        else if (targetWidth + targetLeft <= viewportWidth - offset) {
            width = viewportWidth - (targetLeft + offset);
        }
        element.style['max-width'] = width + 'px';
    }
    else {
        element.style['min-width'] = width + 'px';
    }
    element.style.top = top + viewPortScroll + 'px';
    element.style[direction] = left + 'px';
};
export const getScrollbarWidth = (forceRefresh = false) => {
    if (!forceRefresh && scrollbarWidth !== null) {
        return scrollbarWidth;
    }
    if (typeof document !== 'undefined') {
        const div = document.createElement('div');
        div.style.width = '100';
        div.style.height = '100';
        div.style.position = 'absolute';
        div.style.top = '-9999';
        div.style.overflow = 'scroll';
        // div.style.MsOverflowStyle = 'scrollbar';
        document.body.appendChild(div);
        scrollbarWidth = div.offsetWidth - div.clientWidth;
        document.body.removeChild(div);
    }
    else {
        scrollbarWidth = 0;
    }
    return scrollbarWidth || 0;
};
export const setFocus = (element) => {
    if (element) {
        if (element.current) {
            element.current.focus();
        }
        else {
            element.focus();
        }
    }
};
export const setBlur = (element) => {
    if (element) {
        if (element.current) {
            element.current.blur();
        }
        else {
            element.blur();
        }
    }
};
export const findPreviousInput = (element) => {
    const previousElement = element.parentElement.previousElementSibling;
    if (previousElement && previousElement.childNodes) {
        const inputNode = findNode(previousElement.childNodes, element.tagName.toLowerCase());
        if (inputNode) {
            return inputNode;
        }
    }
    const prevElChildren = previousElement && previousElement.previousElementSibling && previousElement.previousElementSibling.childNodes;
    if (!previousElement || !prevElChildren) {
        return null;
    }
    return findNode(prevElChildren, element.tagName.toLowerCase());
};
export const findNextInput = (element) => {
    const nextElement = element.parentElement.nextElementSibling;
    if (nextElement && nextElement.childNodes) {
        const inputNode = findNode(nextElement.childNodes, element.tagName.toLowerCase());
        if (inputNode) {
            return inputNode;
        }
    }
    const nextElChildren = nextElement && nextElement.nextElementSibling && nextElement.nextElementSibling.childNodes;
    if (!nextElement || !nextElChildren) {
        return null;
    }
    return findNode(nextElChildren, element.tagName.toLowerCase());
};
export const findNode = (nodeList, tagName) => {
    return Object.values(nodeList).find((node) => node.tagName && node.tagName.toLowerCase() === tagName);
};
export const focusOnSiblingAccordion = (accordion, key) => {
    const card = accordion.parentElement.parentElement;
    const accordionGroup = card.parentElement;
    let siblingToFocus;
    if (key === keyCodes.DOWN) {
        siblingToFocus = card.nextElementSibling;
    }
    if (key === keyCodes.UP) {
        siblingToFocus = card.previousElementSibling;
    }
    if (siblingToFocus) {
        setFocus(siblingToFocus.firstChild.firstChild);
    }
    else {
        if (key === keyCodes.DOWN) {
            setFocus(accordionGroup.firstChild.firstChild.firstChild);
        }
        if (key === keyCodes.UP) {
            setFocus(accordionGroup.lastChild.firstChild.firstChild);
        }
    }
};
export const getCharCode = (e) => {
    return e.charCode || e.keyCode || e.which;
};
export const getCtrlKey = (e) => {
    return e.ctrlKey;
};
export const getDOMNodeProperty = (node, property) => {
    return window.getComputedStyle(node, null).getPropertyValue(property);
};
export const getLineHeight = (element) => {
    let value = getDOMNodeProperty(element, 'line-height');
    if (value === 'normal') {
        //https://developer.mozilla.org/en-US/docs/Web/CSS/line-height
        //Depends on the user agent. Desktop browsers (including Firefox)
        //use a default value of roughly 1.2, depending on the element's font-family.
        value = parseInt(getDOMNodeProperty(element, 'font-size')) * 1.2;
    }
    return value;
};
export const isInDOMSubtree = (element, subtreeRoot) => {
    return subtreeRoot && (element === subtreeRoot || subtreeRoot.contains(element));
};
export const isRtl = () => {
    return document.documentElement.dir === 'rtl';
};
export const overflowStyle = (element) => {
    const { overflow, overflowX, overflowY } = window.getComputedStyle(element);
    return '' + overflow + overflowX + overflowY;
};
export const getScrollableParents = (element) => {
    const parentElements = new Map();
    const registerParentElement = (element) => {
        const { scrollLeft = 0, scrollTop = 0 } = element;
        parentElements.set(element, { scrollLeft, scrollTop });
    };
    let parent = element.parentElement;
    while (parent) {
        if (OVERFLOW_REGEXP.test(overflowStyle(parent))) {
            registerParentElement(parent);
        }
        parent = parent.parentElement;
    }
    registerParentElement(window);
    return parentElements;
};
export const getFocusableElements = () => {
    return FOCUSABLE_ELEMENTS;
};
export const escape = (value = '', replaceValue = '') => {
    const result = String(value === null ? '' : value);
    return result.replace(/[-[\]{}()*+?.,\\^$|#&%><\s]/g, replaceValue);
};
// const measureText = (elementRef, text): number => {
//     const styles = getComputedStyle(elementRef);
//     const fontSize = styles.getPropertyValue('font-size');
//     const fontFamily = styles.getPropertyValue('font-family');
//     const canvas = measureText.canvas || (measureText.canvas = document.createElement('canvas'));
//     const canvasContext = canvas.getContext('2d');
//     canvasContext.font = `${fontSize} ${fontFamily}`;
//     const measure = canvasContext.measureText(text);
//     return measure;
// };
export default {
    calculateElementPosition,
    findNextInput,
    findPreviousInput,
    getCharCode,
    getCtrlKey,
    getDOMNodeProperty,
    getLineHeight,
    getScrollableParents,
    getScrollbarWidth,
    isInDOMSubtree,
    isRtl,
    setBlur,
    setFocus,
    getFocusableElements,
    focusOnSiblingAccordion,
    escape,
    // measureText,
};
