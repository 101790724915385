// consumed by schedules module
export const deletePermission = 'Delete';
export const schedulesAdd = 'SchedulesAdd';
export const modify = 'Modify';
export const assign = 'Assign';

// permissions for sidebar
export const godMode = 'godMode';
export const rootDivision = 'rootDivision';
export const createGroup = 'createGroup';
export const createNotification = 'createNotification';
export const useGeneral = 'useGeneral';
export const useEmergency = 'useEmergency';
export const createUser = 'createUser';
export const deleteGroup = 'deleteGroup';
export const deleteNotification = 'deleteNotification';
export const deleteReport = 'deleteReport';
export const deleteUser = 'deleteUser';
export const editAdHoc = 'editAdHoc';
export const editAni = 'editAni';
export const editCustomVerbiage = 'editCustomVerbiage';
export const editGroup = 'editGroup';
export const editIncident = 'editIncident';
export const editMapLayers = 'editMapLayers';
export const editNotification = 'editNotification';
export const editOrgNodes = 'editOrgNodes';
export const editPronunciation = 'editPronunciation';
export const editTopic = 'editTopic';
export const editUser = 'editUser';
export const initiateNotification = 'initiateNotification';
export const privateAccess = 'privateAccess';
export const setSMimeKey = 'setSMimeKey';
export const useTopic = 'useTopic';
export const viewAudit = 'viewAudit';
export const viewDivision = 'viewDivision';
export const viewGroup = 'viewGroup';
export const viewIncident = 'viewIncident';
export const viewMapLayers = 'viewMapLayers';
export const viewNotification = 'viewNotification';
export const viewReport = 'viewReport';
export const viewUser = 'viewUser';
export const adhocReports = 'adhocReports';
export const callBridge = 'callBridge';
export const cascade = 'cascade';
export const customReports = 'customReports';
export const deleteOrphanedReports = 'deleteOrphanedReports';
export const dtmfInitiation = 'dtmfInitiation';
export const dtmfLogin = 'dtmfLogin';
export const editMyLogin = 'editMyLogin';
export const editMyProfile = 'editMyProfile';
export const editRoles = 'editRoles';
export const emailInitiation = 'emailInitiation';
export const exportUser = 'exportUser';
export const gis = 'gis';
export const gisConfig = 'gisConfig';
export const grantLogin = 'grantLogin';
export const importUser = 'importUser';
export const locationManager = 'locationManager';
export const locationOverride = 'locationOverride';
export const manageApiKeys = 'manageApiKeys';
export const mobileInitiation = 'mobileInitiation';
export const myReport = 'myReport';
export const notificationGroups = 'notificationGroups';
export const notificationInbox = 'notificationInbox';
export const orgAdmin = 'orgAdmin';
export const protectedData = 'protectedData';
export const searchHeader = 'searchHeader';
export const setPriority = 'setPriority';
export const substituteUser = 'substituteUser';
export const summarySend = 'summarySend';
export const validateRecipient = 'validateRecipient';
export const viewBillAmount = 'viewBillAmount';
export const viewBilling = 'viewBilling';
export const viewDashboard = 'viewDashboard';
export const viewMyProfile = 'viewMyProfile';
export const viewOrphanedReports = 'viewOrphanedReports';
export const webLogin = 'webLogin';
export const webInitiation = 'webInitiation';
export const topicSubscription = 'topicSubscription';
export const topicSuppression = 'topicSuppression';
export const viewSOS = 'viewSOS';
export const viewLookout = 'viewLookout';
export const manageDeviceTypes = 'manageDeviceTypes';
export const roleTemplates = 'roleTemplates';
export const customizeDivisions = 'customizeDivisions';
export const viewReporting = 'viewReporting';
export const assignSmsProfile = 'assignSmsProfile';
export const editSMSList = 'editSMSList';

//Schedules
export const createSchedule = 'createGroup';
export const deleteSchedule = 'deleteGroup';
export const editSchedule = 'editGroup';
export const viewSchedule = 'viewGroup';

//LockBox
export const viewLockboxShared = 'viewLockboxShared';
export const editLockboxShared = 'editLockboxShared';
export const createLockboxShared = 'createLockboxShared';
export const deleteLockboxShared = 'deleteLockboxShared';

//WeatherAndEvents
export const createWeatherAndEvents = 'createWeatherAndEvents';
export const deleteWeatherAndEvents = 'deleteWeatherAndEvents';
export const editWeatherAndEvents = 'editWeatherAndEvents';
export const viewWeatherAndEvents = 'viewWeatherAndEvents';

// Control Center Access
export const FBA_controlcenter = 'FBA_controlcenter';

//Features by Account
export const FBA_RTBF = 'FBA_RTBF';
export const FBA_recipientSchedule = 'FBA_recipientschedule';
export const FBA_manageDeviceTypes = 'FBA_manageDeviceTypes';
export const FBA_customizeDivisions = 'FBA_customizeDivisions';
export const FBA_roleTemplates = 'FBA_roleTemplates';
export const FBA_privateAddressTypes = 'FBA_privateAddressTypes';
export const FBA_selfRegPortal = 'FBA_selfRegPortals';
export const FBA_announcementsTool = 'FBA_announcementsTool';
export const FBA_announcementsInbox = 'FBA_announcementsInbox';
export const FBA_internalConference = 'FBA_internalConference';
export const FBA_followUps = 'FBA_followUps';
export const FBA_opt_in = 'FBA_opt_in';
export const FBA_cascadeNotifications = 'FBA_cascadeNotifications';
export const FBA_displayCountryFlags = 'FBA_displayCountryFlags';
export const FBA_emailAttachment = 'FBA_emailAttachment';
export const FBA_smsSegmentCount = 'FBA_smsSegmentCount';
export const FBA_integrationFacebook = 'FBA_integrationFacebook';
export const FBA_integrationTwitter = 'FBA_integrationTwitter';
export const FBA_integrationMsTeams = 'FBA_integrationMsTeams';
export const FBA_integrations = 'FBA_integrations';
export const FBA_integrationSlack = 'FBA_integrationSlack';
export const FBA_smsProfiles = 'FBA_smsProfiles';
export const FBA_customtts = 'FBA_customtts';
export const FBA_duplicateFilters = 'FBA_duplicateFilters';
export const FBA_divisions = 'FBA_divisions';
export const FBA_subdivisions = 'FBA_subdivisions';
export const FBA_customReports = 'FBA_customReports';
export const FBA_allowBlackListedSms = 'FBA_allowBlackListedSms';
export const FBA_resetSmsFlagAllowed = 'FBA_resetSmsFlagAllowed';
export const FBA_scenarioLibrary = 'FBA_scenarioLibrary';
export const FBA_nextGenSendingEngine = 'FBA_nextGenSendingEngine';
export const weatherAndEventsEurope = 'weatherAndEventsEurope';
export const weatherAndEventsUSA = 'weatherAndEventsUSA';
export const weatherAndEventsAustralia = 'weatherAndEventsAustralia';
export const weatherAndEventsJapan = 'weatherAndEventsJapan';
export const weatherAndEventsCanada = 'weatherAndEventsCanada';
export const weatherAndEventsGlobal = 'weatherAndEventsGlobal';
export const FBA_mfa = 'FBA_mfa';
export const FBA_enableKatmaiReads = 'FBA_enableKatmaiReads';
export const FBA_lockboxShared = 'FBA_lockboxShared';
export const FBA_OPIncidentManagement = 'FBA_OPIncidentManagement';
export const FBA_doNotContact = 'FBA_doNotContact';
export const FBA_riskintelligenceFF = 'FBA_riskintelligenceFF';
export const FBA_linkedAlerts = 'FBA_linkedAlerts';
export const FBA_wtpCustomer = 'FBA_wtpCustomer';
export const FBA_RITravelManagement = 'FBA_RITravelManagement';
export const FBA_risklineTravelData = 'FBA_risklineTravelData';

//Roles
export const grantLoginAccess = 'grantLoginAccess';
export const checkIn = 'checkIn';
export const quickLaunch = 'quickLaunch';
export const sendSOS = 'sendSOS';
export const sendLookout = 'sendLookout';
export const editEvent = 'editEvent';
export const viewEvent = 'viewEvent';
export const createEvent = 'createEvent';
export const deleteEvent = 'deleteEvent';
export const editLookout = 'editLookout';
export const editSOS = 'editSOS';
//Public Feeds
export const viewTwitter = 'viewTwitter';
export const editTwitter = 'editTwitter';
export const useTwitter = 'useTwitter';
export const configureTwitter = 'configureTwitter';

export const createIntegration = 'createIntegration';
export const editIntegration = 'editIntegration';
export const viewIntegration = 'viewIntegration';
export const deleteIntegration = 'deleteIntegration';

export const manage2FASettings = 'manage2FASettings';

//Do not Contact
export const createDoNotContact = 'createDoNotContact';
export const viewDoNotContact = 'viewDoNotContact';
export const editDoNotContact = 'editDoNotContact';
export const deleteDoNotContact = 'deleteDoNotContact';
