import { Layout } from '@components/Layout/Layout';
import { t } from '@lingui/macro';
import React from 'react';
import style from './ErrorPage.module.scss';
const ErrorPage = ({ title, description, errorImage }) => {
    return (<Layout>
            <div className={style.root}>
                <div className={style.content}>
                    <h1 className={style.title}>{title}</h1>

                    <img className={style.infographic} src={errorImage} alt={t `Error Page Infographic`}/>
                    <div className={style.descriptionBlock}>
                        <h2 className={style.description}>{description}</h2>
                    </div>
                </div>
            </div>
        </Layout>);
};
export default ErrorPage;
