import { t } from '@lingui/macro';
import { identityRoutes } from '@routes/identityRoutes';
import configService from '@services/config.service';
import { createIdentityManager, onSignin } from '@services/identityManager';
import SessionStorageUtility from '@utility/sessionStorageUtility';
import { notifyError } from '@utils/notifyError';
import { parseUrl } from '@utils/url';
import LoadingOverlay from '@corecomponents/Loading/LoadingOverlay';
import React from 'react';
export const SignInCallback = () => {
    const redirectUser = async () => {
        const newSession = sessionStorage.getItem('newSession');
        if (newSession === 'true') {
            sessionStorage.removeItem('newSession');
            // Redirect to the main page or wherever your sign-in process starts
            return;
        }
        const { IdentityServerBaseUrl = '', IdentityServerRedirectUrl = '', IdentityServerClientId = '', IdentityServerClientScope = '', SilentAuthorization = false, SessionMonitoring = false, SelfRegistrationBaseUrl = '', } = configService || {};
        const portalConfiguration = SessionStorageUtility.getPortalConfiguration();
        const identityManager = createIdentityManager({
            identityServerBaseUrl: IdentityServerBaseUrl,
            identityServerRedirectUrl: IdentityServerRedirectUrl,
            clientId: IdentityServerClientId,
            scope: IdentityServerClientScope,
            enableSilentAuthorization: SilentAuthorization,
            enableSessionMonitoring: SessionMonitoring,
            origin: window.location.toString().replace('/contact', ''),
            organizationUuid: portalConfiguration?.organizationUuid ?? '',
            divisionUuid: portalConfiguration?.divisionUuid ?? '',
            portalId: portalConfiguration?.id ?? 0,
            selfRegBaseUrl: SelfRegistrationBaseUrl,
            signinCallbackPath: identityRoutes.signinCallback,
            renewCallbackPath: identityRoutes.accessTokenRenewCallback,
            signoutCallbackPath: identityRoutes.signoutCallback,
            onSignin,
        });
        const origin = parseUrl(parseUrl(window.location.toString()).query.find((q) => q.name === 'origin')?.value ??
            window.location.toString());
        if (window.location.host !== origin.host) {
            const updatedUrl = `${origin.protocol}://${origin.host}/${parseUrl(window.location.toString()).path}?${parseUrl(window.location.toString())
                .query.map((q) => `${q.name}=${q.value}`)
                .join('&')}`;
            location.assign(updatedUrl);
            return;
        }
        //Save the Sign-In callback query params in session storage
        identityManager.processSigninRedirectCallback().catch((e) => {
            console.error('Process SignIn Redirect Callback error', e);
            if (e?.toString()?.includes('401')) {
                window.location.href = '/registrationsuccessful'; //redirect to intermediate new processing page
            }
            notifyError(e, t `You do not have permission to login to the Portal. Please contact your Org Administrator.`);
        });
    };
    redirectUser();
    return <LoadingOverlay />;
};
