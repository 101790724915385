class BannerObserver {
    observers;
    constructor() {
        this.observers = [];
    }
    subscribe(fn) {
        this.observers.push(fn);
    }
    unsubscribe(fn) {
        this.observers = this.observers.filter((subscriber) => subscriber !== fn);
    }
    broadcast(banner) {
        this.observers.forEach((subscriber) => subscriber(banner));
    }
}
export const bannerObserver = new BannerObserver();
