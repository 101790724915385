import React, { createContext, useContext } from 'react';
import getApiClient from '@utils/getApiClient';
import { useQuery } from '@tanstack/react-query';
const DEFAULT_CONTEXT = {
    customHelpText: null,
    isCustomHelpTextLoading: false,
};
const [portalUrl] = window.location.pathname.split('/').filter(Boolean);
const ui_lang = new URLSearchParams(window.location.search).get('ui_lang');
const CUSTOM_HELPTEXT_KEY = `customHelpText_${portalUrl}_${ui_lang}`;
const client = getApiClient();
const CustomHelpTextContext = createContext(DEFAULT_CONTEXT);
export const useCustomHelpTextContext = () => useContext(CustomHelpTextContext);
const CustomHelpTextProvider = ({ children }) => {
    const { data: customHelpText, isFetching: isCustomHelpTextLoading } = useQuery([CUSTOM_HELPTEXT_KEY], async () => {
        const { data = DEFAULT_CONTEXT.customHelpText } = await client.get(`api/portal/${portalUrl}/help${ui_lang ? '?languageId=' + ui_lang : ''}`);
        return data?.data;
    }, {
        placeholderData: DEFAULT_CONTEXT.customHelpText,
        staleTime: Infinity,
        onError: (error) => {
            console.error('GET_ERROR', error);
        },
        retry: 3,
        enabled: portalUrl?.toLocaleLowerCase() !== 'identity',
    });
    const values = {
        customHelpText,
        isCustomHelpTextLoading,
    };
    return <CustomHelpTextContext.Provider value={values}>{children}</CustomHelpTextContext.Provider>;
};
export default CustomHelpTextProvider;
