import { t } from '@lingui/macro';
import Popover from '@corecomponents/Popover/Popover';
import GlobusIcon from '@icons/Globus';
import DotsLoadingSpinner from '@corecomponents/Loading/DotsLoadingSpinner';
import Button from '@core/Button';
import React, { useState, useCallback } from 'react';
import LocaleButton from './components/LocaleButton';
import getClassName from '@utils/getClassName';
import styles from './LanguageDropDown.module.scss';
import { useLanguageServiceContext } from '@context/LanguageServiceProvider/LanguageServiceProvider';
import PopoverBody from '@corecomponents/Popover/PopoverBody';
import PopoverHeader from '@corecomponents/Popover/PopoverHeader';
const LanguageDropDown = ({ className }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { localeIsLoading, localeData, selectedLanguage: { translatedName, cultureRegionCode, isoCode }, setLanguage, } = useLanguageServiceContext();
    const [rootClass, getChildClass] = getClassName({
        className,
        styles,
        modifiers: {
            loading: localeIsLoading,
        },
        rootClass: 'languageDropDown',
    });
    const handlePopup = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);
    const handleLanguageChange = useCallback((locale) => {
        setLanguage(locale);
        handlePopup();
    }, [setLanguage, handlePopup]);
    return (<div className={rootClass}>
            {/* Changing button to display: hidden when loading because the popover always
            needs a component to attach to, should be useRef but we're stuck with this
            until we move off OUC */}
            {localeIsLoading ? (<DotsLoadingSpinner className={getChildClass('spinner')} data-testid="LanguageDropDown-Spinner"/>) : (<>
                    <Button id="lang" data-testid="LanguageDropDown-Button" aria-label={`${translatedName} (${cultureRegionCode})`} color="transparent" className={getChildClass('languageButton')} onClick={handlePopup} variant="text">
                        <span className={getChildClass('languageButtonText')}>
                            {translatedName} ({cultureRegionCode})
                            <GlobusIcon className={getChildClass('languageIcon')}/>
                        </span>
                    </Button>
                    <Popover target="lang" shouldFocus isOpen={isOpen} trigger="manual" onClose={handlePopup} position="bottom-left" size="md">
                        <PopoverHeader className={getChildClass('popoverHeader')} handleClose={handlePopup}>
                            <GlobusIcon className={getChildClass('worldIcon')} color="primary" style={{ color: '#3777bc', height: '1.5rem', width: '1.5rem' }}/>
                            {t `Languages`}
                        </PopoverHeader>
                        <PopoverBody className={getChildClass('popoverBody')}>
                            {localeData?.length &&
                localeData.map((locale) => (<LocaleButton key={locale.lcId} handleLanguageChange={handleLanguageChange} locale={locale} selected={isoCode === locale?.isoCode}/>))}
                        </PopoverBody>
                    </Popover>
                </>)}
        </div>);
};
export default LanguageDropDown;
