import AppProviders from '@context/AppProviders';
import IdentityProvider from '@context/IdentityContext';
import { i18n } from '@lingui/core';
import { en } from 'make-plural/plurals';
import React from 'react';
import ReactDOM from 'react-dom';
import 'scss/style.scss';
import App from './App';
import './index.scss';
import { messages } from './locales/en-US/messages';
import { BrowserRouter } from 'react-router-dom';
import SessionProvider from 'context/sessionContext';
// ---------------------------------------------------------------------------
// In order to get the Favicon to copy properly, we need to import it.
// Several attempts have been made to not require this import, with no luck.
// Do not delete the Favicon import
// ---------------------------------------------------------------------------
i18n.loadLocaleData({
    'en-US': { plurals: en },
});
i18n.load('en-US', messages);
i18n.activate('en-US');
ReactDOM.render(<React.StrictMode>
        <SessionProvider>
            <AppProviders>
                <IdentityProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </IdentityProvider>
            </AppProviders>
        </SessionProvider>
    </React.StrictMode>, document.getElementById('root'));
