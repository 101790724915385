import React, { forwardRef } from 'react';
import getClassName from '@utils/getClassName';
import styles from './Typography.module.scss';
const defaultStyleMapping = {
    caption: 'p12',
    body1: 'body14',
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    p8: 'p8',
    p10: 'p10',
    p12: 'p12',
    p14: 'p14',
    p16: 'p16',
    p18: 'p18',
    p24: 'p24',
    p30: 'p30',
    p48: 'p48',
    span8: 'p8',
    span10: 'p10',
    span12: 'p12',
    span14: 'p14',
    span16: 'p16',
    span18: 'p18',
    span24: 'p24',
    span30: 'p30',
    span48: 'p48',
};
const defaultTypographyMapping = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    p8: 'p',
    p10: 'p',
    p12: 'p',
    p14: 'p',
    p16: 'p',
    p18: 'p',
    p24: 'p',
    p30: 'p',
    span8: 'span',
    span10: 'span',
    span12: 'span',
    span14: 'span',
    span16: 'span',
    span18: 'span',
    span24: 'span',
    span30: 'span',
};
export const Typography = forwardRef(({ children, className, color = 'initial', component, uppercase = false, variant = 'h1', ...otherProps }, ref) => {
    const classPrefix = defaultStyleMapping[variant];
    const [rootClass] = getClassName({
        rootClass: 'typography',
        className,
        modifiers: {
            [classPrefix]: !!classPrefix,
            [color]: !!color,
            uppercase,
        },
        styles,
    });
    const Component = component || defaultTypographyMapping[variant] || 'span';
    return (<Component className={rootClass} ref={ref} {...otherProps}>
                {children}
            </Component>);
});
Typography.displayName = 'Typography';
export default Typography;
