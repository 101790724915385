import jwt from 'jwt-decode';
export default class JwtService {
    decodedPayload = null;
    getOrganizationId = () => {
        return this.getPayload()?.org;
    };
    getPermissionsClaims = () => {
        // eslint-disable-next-line no-unused-vars
        const { sub, org, exp, ...permissions } = this.getPayload() || {};
        return permissions;
    };
    getPayload = () => {
        if (!this.decodedPayload) {
            this.decodedPayload = this.getData('claims');
        }
        return { ...this.decodedPayload };
    };
    isTokenExpired = () => {
        const data = this.selectContaining('oidc.user');
        const exp = data?.expires_at;
        if (exp) {
            const date = new Date(0);
            date.setUTCSeconds(exp);
            return date.valueOf() <= new Date().valueOf();
        }
        return true;
    };
    getToken = (tokenType) => {
        const data = this.selectContaining('oidc.user');
        if (data && data[tokenType]) {
            return data[tokenType];
        }
        return null;
    };
    setToken = () => {
        const data = this.selectContaining('oidc.user');
        if (data?.access_token) {
            const claims = jwt(data.access_token);
            this.setData('claims', claims);
        }
    };
    setClaims() {
        const data = this.selectContaining('oidc.user');
        if (data?.access_token) {
            const claims = jwt(data.access_token);
            this.setData('claims', claims);
        }
    }
    removeToken() {
        this.clearKeysContaining('oidc.user');
    }
    removeClaims() {
        this.clearKeys(['claims']);
    }
    getData = (key) => {
        const item = localStorage.getItem(key);
        return !!item ? JSON.parse(item) : '';
    };
    setData = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    };
    selectContaining = (prefix) => {
        for (const key in localStorage) {
            if (!localStorage.hasOwnProperty(key)) {
                continue;
            }
            if (key.includes(prefix)) {
                return JSON.parse(localStorage.getItem(key) || '');
            }
        }
        return;
    };
    clear = () => {
        localStorage.clear();
    };
    clearKeys = (value) => {
        Array.isArray(value) &&
            value.forEach((item) => {
                localStorage.removeItem(item);
            });
    };
    clearKeysContaining = (prefix) => {
        for (const key in localStorage) {
            if (!localStorage.hasOwnProperty(key)) {
                continue;
            }
            if (key.includes(prefix)) {
                localStorage.removeItem(key);
            }
        }
    };
}
