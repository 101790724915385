const separate = (str, separator) => {
    const index = str.indexOf(separator);
    if (index < 0) {
        return null;
    }
    const left = str.substr(0, index);
    const right = str.substr(index + separator.length);
    return [left, right];
};
const separateLeft = (str, separator) => {
    const parts = separate(str, separator);
    return parts === null ? ['', str] : parts;
};
const separateRight = (str, separator) => {
    const parts = separate(str, separator);
    return parts === null ? [str, ''] : parts;
};
const splitAll = (str, separator) => (str ? str.split(separator) : []);
const trimLeft = (str, toTrim) => (str.startsWith(toTrim) ? str.substr(toTrim.length) : str);
const parseQueryParam = (param) => {
    const [encodedName, encodedValue] = separateRight(param, '=');
    return {
        name: decodeURIComponent(encodedName),
        value: decodeURIComponent(encodedValue),
    };
};
const stringifyQueryParam = (param) => encodeURIComponent(param.name) + '=' + encodeURIComponent(param.value);
const parseQuery = (query) => splitAll(query, '&').map(parseQueryParam);
const stringifyQuery = (query) => query.map(stringifyQueryParam).join('&');
export const parseUrl = (url) => {
    const [protocol, hostPathQueryFragment] = separateLeft(url, '://');
    const [hostAndPath, queryAndFragment] = separateRight(hostPathQueryFragment, '?');
    const [host, path] = separateRight(hostAndPath, '/');
    const [queryStr, fragment] = separateRight(queryAndFragment, '#');
    const query = parseQuery(queryStr);
    return { protocol, host, path, query, fragment };
};
export const stringifyUrl = (url) => {
    let urlStr = url.host;
    if (url.protocol) {
        urlStr = url.protocol + '://' + urlStr;
    }
    if (url.path) {
        urlStr += '/' + url.path;
    }
    if (url.query.length) {
        urlStr += '?' + stringifyQuery(url.query);
    }
    if (url.fragment) {
        urlStr += '#' + url.fragment;
    }
    return urlStr;
};
export const replaceQueryParam = (url, param) => {
    const query = url.query.map((p) => (p.name === param.name ? param : p));
    return { ...url, query };
};
export const appendQueryParam = (url, param) => {
    const query = [...url.query, param];
    return { ...url, query };
};
export const appendOrReplaceQueryParam = (url, name, modify) => {
    const [param] = url.query.filter((queryParam) => queryParam.name === name);
    return param ? replaceQueryParam(url, modify(param)) : appendQueryParam(url, modify({ name, value: '' }));
};
export const appendPath = (url, pathToAppend) => {
    pathToAppend = trimLeft(pathToAppend, '/');
    const path = url.path ? url.path + '/' + pathToAppend : pathToAppend;
    return { ...url, path };
};
