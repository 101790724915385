import React from 'react';
import styles from './Link.module.scss';
import { Link as RrLink } from 'react-router-dom';
import getClassName from '@utils/getClassName';
import { useThemeContext } from '@context/ThemeProvider';
const Link = ({ className, color, href, onSurface, onPrimary, ...linkProps }) => {
    const { themeColor } = useThemeContext();
    const [rootClass] = getClassName({
        rootClass: 'link',
        className,
        modifiers: {
            onSurface,
            onPrimary,
            [color]: !!color,
            [themeColor]: !!themeColor && !onSurface && !color && !onPrimary,
        },
        styles,
    });
    return <RrLink {...linkProps} className={rootClass}/>;
};
export default Link;
