import { isEmpty, reduce } from 'lodash';
import * as rules from './accessRules';
import JwtService from './jwt.service';
export default class PermissionService {
    _permissions = {};
    constructor() { }
    get permissions() {
        if (!Object.keys(this._permissions).length) {
            const claims = new JwtService().getPermissionsClaims();
            this._permissions = this.generatePermissions(claims, rules.default);
        }
        return this._permissions;
    }
    generatePermissions(claims, defaultRules) {
        return reduce(defaultRules, (acc, fn, name) => {
            acc[name] = function () {
                if (isEmpty(claims)) {
                    return false;
                }
                const args = Array.from(arguments);
                return fn(claims, ...args);
            };
            return acc;
        }, {});
    }
}
