import React, { createContext, useContext } from 'react';
import getApiClient from '@utils/getApiClient';
import { useQuery } from '@tanstack/react-query';
export var PolicyDisplayOption;
(function (PolicyDisplayOption) {
    PolicyDisplayOption["CUSTOM_TEXT"] = "customText";
    PolicyDisplayOption["URL"] = "url";
    PolicyDisplayOption["DO_NOT_DISPLAY"] = "doNotDisplay";
})(PolicyDisplayOption || (PolicyDisplayOption = {}));
export const policiesTitle = [
    {
        id: 'privacyPolicy',
        value: 'Privacy Policy',
    },
    {
        id: 'termsAndConditions',
        value: 'Terms of use',
    },
    {
        id: 'userAgreement',
        value: 'User Agreement',
    },
];
const DEFAULT_CONTEXT = {
    customPolicies: [],
    isCustomPoliciesLoading: false,
};
const [portalUrl] = window.location.pathname.split('/').filter(Boolean);
const ui_lang = new URLSearchParams(window.location.search).get('ui_lang');
const CUSTOM_POLICIES_KEY = `customPolicies_${portalUrl}_${ui_lang}`;
const client = getApiClient();
const CustomPoliciesContext = createContext(DEFAULT_CONTEXT);
export const useCustomPoliciesContext = () => useContext(CustomPoliciesContext);
const CustomPoliciesProvider = ({ children }) => {
    const { data: customPolicies, isFetching: isCustomPoliciesLoading } = useQuery([CUSTOM_POLICIES_KEY], async () => {
        const { data = DEFAULT_CONTEXT.customPolicies } = await client.get(`api/portal/${portalUrl}/policies${ui_lang ? '?languageId=' + ui_lang : ''}`);
        return data?.data;
    }, {
        placeholderData: DEFAULT_CONTEXT.customPolicies,
        staleTime: Infinity,
        onError: (error) => {
            console.error('GET_ERROR', error);
        },
        retry: 3,
        enabled: portalUrl?.toLocaleLowerCase() !== 'identity',
    });
    const values = {
        customPolicies,
        isCustomPoliciesLoading,
    };
    return <CustomPoliciesContext.Provider value={values}>{children}</CustomPoliciesContext.Provider>;
};
export default CustomPoliciesProvider;
