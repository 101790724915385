import * as permissionNames from 'common/constants/permissions';

const canManageDoNotContact = permissions => {
    return (
        !!permissions[permissionNames.FBA_doNotContact] && !!permissions.viewDoNotContact
    );
};

export default {
    canManageDoNotContact
};
