import domUtils from '../core-utils/domUtils';
const container = new Map();
const popoverManager = {
    register(id, element) {
        container.set(id, element);
    },
    unregister(id) {
        container.delete(id);
    },
    canClose(id, element) {
        const ids = Array.from(container.keys());
        const currentIndex = ids.indexOf(id);
        if (currentIndex > -1 && currentIndex === ids.length - 1) {
            return !domUtils.isInDOMSubtree(element, container.get(ids[currentIndex]));
        }
        return false;
    },
};
export default popoverManager;
