import { WebStorageStateStore, SessionMonitor, Log, UserManager, } from 'oidc-client';
import * as Oidc from 'oidc-client'; // needed for weird workaround below...
import { parseUrl, stringifyUrl, appendPath, appendQueryParam, appendOrReplaceQueryParam, replaceQueryParam, } from '@utils/url';
import { idToken, identityRoutes } from '@routes/identityRoutes';
import configService from './config.service';
import JwtService from './jwt.service';
import namespaceService from './namespace.service';
const getOrigin = () => {
    const origin = namespaceService.isLocalhost()
        ? `${namespaceService.getProtocol()}//${namespaceService.getExternalDomain()}`
        : `${namespaceService.getProtocol()}//accountportal.${namespaceService.getExternalDomain()}`;
    return origin;
};
export const RedirectNavigatorDecorator = {
    changeRedirectUrls: (navigator, changeUrl) => {
        const Decorated = class {
            get url() {
                return navigator.url;
            }
            async prepare() {
                await navigator.prepare();
                return this;
            }
            navigate(params) {
                const modifiedParams = withUrl(params, changeUrl);
                return navigator.navigate(modifiedParams);
            }
        };
        return new Decorated();
    },
};
const IFrameWindowDecorator = {
    changeRedirectUrls: (frame, changeUrl) => {
        return {
            navigate: (params) => {
                const modifiedParams = withUrl(params, changeUrl);
                return frame.navigate(modifiedParams);
            },
            close: () => frame.close(),
        };
    },
};
export const IFrameNavigatorDecorator = {
    changeRedirectUrls: (navigator, changeUrl) => {
        return {
            prepare: async (params) => {
                const frame = await navigator.prepare(params);
                return IFrameWindowDecorator.changeRedirectUrls(frame, changeUrl);
            },
            callback: (url) => navigator.callback(url),
        };
    },
};
// All navigators
const createDefaultNavigators = () => {
    const userManager = new UserManager({
        monitorSession: false,
        automaticSilentRenew: false,
    });
    const defaults = userManager.settings;
    return {
        iframe: defaults.iframeNavigator,
        redirect: defaults.redirectNavigator,
    };
};
export const createNavigatorsWithUrlReplacement = (changeUrl) => {
    const navigators = createDefaultNavigators();
    return {
        iframe: IFrameNavigatorDecorator.changeRedirectUrls(navigators.iframe, changeUrl),
        redirect: RedirectNavigatorDecorator.changeRedirectUrls(navigators.redirect, changeUrl),
    };
};
const UserManagerCtor = UserManager;
export function createUserManager(settings) {
    return new UserManagerCtor(settings, undefined, settings.sessionMonitorCtor);
}
export function withUrl(request, changeUrl) {
    if (!request || !request.url) {
        return request;
    }
    const url = parseUrl(request.url);
    const modifiedUrl = stringifyUrl(changeUrl(url));
    return { ...request, url: modifiedUrl };
}
const convertToIdentity = (user) => ({
    id: user.profile.sub || '',
    name: user.profile.name || '',
    accessToken: user.access_token,
});
export function createPortalAppIdentifier(portalId) {
    return btoa(JSON.stringify({ portalId }));
}
export function createPortalAppUrlForStateTransfer(portalString) {
    const portalUrl = new URL(portalString).pathname;
    return btoa(JSON.stringify({ portalUrl }));
}
const formatCheckSessionUrl = (selfRegBaseUrl, originalUrl, portalAppIdentifier) => {
    let url = parseUrl(selfRegBaseUrl);
    url = appendPath(url, '/api/identity/checksession');
    url = appendQueryParam(url, { name: 'descriptor', value: portalAppIdentifier });
    url = appendQueryParam(url, { name: 'checkSessionUrl', value: originalUrl });
    return stringifyUrl(url);
};
// work around CheckSessionIFrame being declared as an interface
// in the type definitions, when in fact this is a constructor function.
export const CheckSessionIFrame = Oidc['CheckSessionIFrame'];
export function createdProxiedCheckSessionIFrameCtor(makeProxyUrl) {
    return function Ctor(callback, clientId, url, interval, stopOnError = true) {
        const proxyUrl = makeProxyUrl(url);
        return new CheckSessionIFrame(callback, clientId, proxyUrl, interval, stopOnError);
    };
}
// exported for testing purposes
export const makeProxiedCheckSessionIFrameCtor = (selfRegBaseUrl, portalAppIdentifier) => {
    return createdProxiedCheckSessionIFrameCtor((originalUrl) => formatCheckSessionUrl(selfRegBaseUrl, originalUrl, portalAppIdentifier));
};
// Some field names below use snake_case, which upsets eslint. There is
// nothing we can do about it, since these names are part of oidc-client
// and OnSolve.IdentityServer contracts.
export const createIdentityManager = ({ identityServerBaseUrl = '', identityServerRedirectUrl = '', clientId = '', scope = '', enableSilentAuthorization = false, enableSessionMonitoring = false, origin = '', organizationUuid = '', divisionUuid = '', portalId = -1, selfRegBaseUrl = '', signinCallbackPath = '', renewCallbackPath = '', signoutCallbackPath = '', onSignin = (decodedPortalUrl) => { }, }) => {
    let currentLanguageId = navigator.language;
    Log.level = Log.WARN;
    Log.logger = console;
    const portalAppIdentifier = createPortalAppIdentifier(portalId);
    const navigators = createNavigatorsWithUrlReplacement((url) => appendOrReplaceQueryParam(url, 'state', (param) => ({
        ...param,
        value: param.value + '_' + createPortalAppUrlForStateTransfer(origin),
    })));
    // To store authentication data for each organization separately, include
    // organization UUID as part of the storage key prefix.
    const storagePrefix = `${organizationUuid}.oidc.`;
    // Identity Server will pass this parameter back to Self Reg
    // in a Branding API request to show branded signin page.
    const getQueryParams = (idTokenValue = '') => ({
        branding_descriptor: portalAppIdentifier,
        organization_uuid: organizationUuid,
        division_uuid: divisionUuid,
        ui_lang: currentLanguageId,
        origin,
        ...(idTokenValue && { id_token_hint: idTokenValue }),
    });
    const userManager = createUserManager({
        // services
        redirectNavigator: navigators.redirect,
        stateStore: new WebStorageStateStore({
            store: window.sessionStorage,
            prefix: storagePrefix,
        }),
        userStore: new WebStorageStateStore({
            store: window.localStorage,
            prefix: storagePrefix,
        }),
        iframeNavigator: navigators.iframe,
        sessionMonitorCtor: function (owner) {
            return new SessionMonitor(owner, makeProxiedCheckSessionIFrameCtor(selfRegBaseUrl, portalAppIdentifier));
        },
        // configuration
        authority: identityServerBaseUrl,
        response_type: 'code',
        response_mode: 'query',
        client_id: clientId,
        scope: 'openid profile ' + scope,
        redirect_uri: `${getOrigin()}${signinCallbackPath}`,
        silent_redirect_uri: `${getOrigin()}${renewCallbackPath}`,
        post_logout_redirect_uri: `${getOrigin()}${signoutCallbackPath}`,
        extraQueryParams: getQueryParams(),
        loadUserInfo: true,
        // This is deliberately disabled. The maintainer of oidc-client library
        // recommends implementing silent renewals explicitly on the application
        // level, instead of using the library's built-in feature. Following that
        // advice, this module implements the silent renewal logic itself.
        // automaticSilentRenew: false,
        // silentRequestTimeout: 30000, // 30 sec
        // monitorSession: enableSessionMonitoring
    });
    const currentUser = async () => {
        const user = await userManager.getUser();
        console.log('UserManager.getUser()  completed', user);
        if (!user || user.expired) {
            return null;
        }
        return convertToIdentity(user);
    };
    const signinRedirect = async () => {
        const user = await userManager.getUser();
        if (user && !user.expired) {
            onSignin();
            return;
        }
        await userManager.clearStaleState();
        return userManager.signinRedirect({
            extraQueryParams: getQueryParams(),
        });
    };
    const modifyRedirectUrl = () => {
        const [, state] = window.location.search
            .split('&')
            .find((param) => param.split('=')[0] === 'state')
            .split('=');
        const [updatedState] = state.split('_');
        const origin = parseUrl(parseUrl(window.location.toString()).query.find((q) => q.name === 'origin')?.value ??
            window.location.toString());
        const updatedUrl = `${origin.protocol}://${origin.host}/${parseUrl(window.location.toString()).path}?${parseUrl(window.location.toString())
            .query.map((q) => `${q.name}=${q.value}`)
            .join('&')}`;
        return {
            oidcUrl: stringifyUrl(replaceQueryParam(parseUrl(updatedUrl), {
                name: 'state',
                value: updatedState,
            })),
        };
    };
    const processSigninRedirectCallback = async () => {
        // ! we have to modify our redirect url before we call signin redirect callback
        const { oidcUrl } = modifyRedirectUrl();
        const user = await userManager.signinRedirectCallback(oidcUrl);
        console.log('signinRedirectCallback completed ', user);
        const origin = stringifyUrl(parseUrl(parseUrl(oidcUrl).query.find((q) => q.name === 'origin')?.value ?? window.location.toString()));
        if (!new JwtService().isTokenExpired()) {
            new JwtService().setClaims();
            onSignin(origin);
        }
    };
    const resetAccessToken = () => handleAccessTokenExpired();
    const attemptRenewAccessToken = async () => {
        // Attempt to request a new access token by making a sign in request in an iframe.
        // If this fails (for example if user's session is also expired in Identity Server),
        // there is not much we can do about it -- we'll need to request a user to login.
        try {
            await userManager.signinSilent();
            console.log('Access token renewed.');
        }
        catch (e) {
            console.warn('Access token renewal failed.', e);
        }
    };
    const processRenewRedirectCallback = async () => {
        await userManager.signinSilentCallback();
    };
    const signoutRedirect = () => {
        const jwt = new JwtService();
        const idTokenValue = jwt.getToken(idToken);
        jwt.removeClaims();
        jwt.removeToken();
        return userManager.signoutRedirect({
            extraQueryParams: getQueryParams(idTokenValue),
        });
    };
    const handleUserCleanData = () => {
        const userManager = createUserManager({
            // services
            stateStore: new WebStorageStateStore({
                store: window.sessionStorage,
                prefix: storagePrefix,
            }),
            userStore: new WebStorageStateStore({
                store: window.localStorage,
                prefix: storagePrefix,
            }),
            redirectNavigator: navigators.redirect,
            iframeNavigator: navigators.iframe,
            sessionMonitorCtor: function (owner) {
                return new SessionMonitor(owner, makeProxiedCheckSessionIFrameCtor(selfRegBaseUrl, portalAppIdentifier));
            },
            // configuration
            authority: identityServerBaseUrl,
            response_type: 'code',
            response_mode: 'query',
            client_id: clientId,
            scope: 'openid profile ' + scope,
            redirect_uri: `${getOrigin()}${signinCallbackPath}`,
            silent_redirect_uri: `${getOrigin()}${renewCallbackPath}`,
            post_logout_redirect_uri: `${getOrigin()}${signinCallbackPath}`,
            extraQueryParams: getQueryParams(),
            loadUserInfo: true,
            // This is deliberately disabled. The maintainer of oidc-client library
            // recommends implementing silent renewals explicitly on the application
            // level, instead of using the library's built-in feature. Following that
            // advice, this module implements the silent renewal logic itself.
            automaticSilentRenew: false,
            silentRequestTimeout: 30000,
            monitorSession: enableSessionMonitoring,
        });
        return userManager.signoutRedirect({
            extraQueryParams: getQueryParams(),
        });
    };
    const handleAccessTokenExpired = async () => {
        await userManager.removeUser();
        signoutRedirect();
    };
    const setCurrentLanguage = (languageId) => {
        currentLanguageId = languageId;
    };
    if (enableSilentAuthorization) {
        // The event will trigger some time prior to access token expiration time
        // (1 minute by default). We'll make an attempt to silently request a new one
        // in an iframe, given a chance that the user's session in the Identity Server
        // has a longer lifetime and is not expired yet.
        userManager.events.addAccessTokenExpiring(() => attemptRenewAccessToken());
    }
    // If the access token is expired (because we were unable to silently renew it,
    // for example), we'll need to clean up the expired user claims and access token
    // we store in a local storage.
    userManager.events.addAccessTokenExpired(() => handleAccessTokenExpired());
    if (enableSessionMonitoring) {
        // We'll do the same cleanup if a user logs out from Identity Server.
        userManager.events.addUserSignedOut(() => handleAccessTokenExpired());
    }
    return {
        currentUser,
        signinRedirect,
        processSigninRedirectCallback,
        processRenewRedirectCallback,
        signoutRedirect,
        resetAccessToken,
        setCurrentLanguage,
        handleUserCleanData,
    };
};
const { IdentityServerBaseUrl = '', IdentityServerRedirectUrl = '', IdentityServerClientId = '', IdentityServerClientScope = '', SilentAuthorization = false, SessionMonitoring = false, SelfRegistrationBaseUrl = '', } = configService || {};
export const onSignin = (decodedPortalUrl) => {
    decodedPortalUrl ? location.assign(decodedPortalUrl) : location.assign('/contact');
};
export const identityManager = createIdentityManager({
    identityServerBaseUrl: IdentityServerBaseUrl,
    identityServerRedirectUrl: IdentityServerRedirectUrl,
    clientId: IdentityServerClientId,
    scope: IdentityServerClientScope,
    enableSilentAuthorization: SilentAuthorization,
    enableSessionMonitoring: SessionMonitoring,
    origin: window.location.toString().replace('/contact', ''),
    organizationUuid: '',
    divisionUuid: '',
    portalId: 0,
    selfRegBaseUrl: SelfRegistrationBaseUrl,
    signinCallbackPath: identityRoutes.signinCallback,
    renewCallbackPath: identityRoutes.accessTokenRenewCallback,
    signoutCallbackPath: identityRoutes.signoutCallback,
    onSignin,
});
