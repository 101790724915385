import React from 'react';
import getClassName from '@utils/getClassName';
import styles from './modal.module.scss';
const ModalFooter = ({ className, children, size, ...otherProps }) => {
    const [rootClass] = getClassName({
        rootClass: 'modal__footer',
        className,
        styles,
        modifiers: {
            [size]: !!size,
        },
    });
    return (<div {...otherProps} className={rootClass}>
            {children}
        </div>);
};
export default ModalFooter;
