export const isValidUrl = (urlString) => {
    try {
        new URL(urlString); // If this fails, it's an invalid URL
        return true;
    }
    catch (error) {
        return false;
    }
};
export const findFirstValidUrl = (divisionMetadata) => {
    for (const obj of divisionMetadata) {
        if (isValidUrl(obj.value)) {
            return obj.value;
        }
    }
    return null;
};
