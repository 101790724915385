import { Layout } from '@components/Layout/Layout';
import { t } from '@lingui/macro';
import { Button } from 'onsolve-ui-components';
import React from 'react';
import { Col, Row } from 'reactstrap';
import style from './DeactivatedContainer.module.scss';
import infographicImage from './images/deactivated_infographic.svg';
const DeactivatedContainer = ({ title, description, goHome }) => {
    return (<Layout>
            <div className={style.root}>
                <div className={style.content}>
                    <h1 className={style.title}>{title}</h1>

                    <img className={style.infographic} src={infographicImage} alt={t `Deactivated Page Infographic`}/>
                    <div className={style.descriptionBlock}>
                        <h2 className={style.description}>{description}</h2>
                        <h2 className={style.description}>
                            {t `You can close the browser or return to the home page.`}
                        </h2>
                    </div>

                    <Row className={style.buttonContainer}>
                        <Col sm="9">
                            <Button className={style.button} onClick={goHome}>
                                {t `Home`}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Layout>);
};
export default DeactivatedContainer;
