import React from 'react';
import styles from './modal.module.scss';
import getClassName from '@utils/getClassName';
import IconButton from '@corecomponents/IconButton';
import Close from '@corecomponents/icons/Close';
import { useThemeContext } from '@context/ThemeProvider';
const ModalHeader = ({ id, title, onClose, onCloseKeyDown }) => {
    const { themeColor } = useThemeContext();
    const [rootClass, getChildClass] = getClassName({
        rootClass: 'modal',
        modifiers: {
            [themeColor]: !!themeColor,
        },
        styles,
    });
    return (<div className={rootClass}>
            <div id={id} className={getChildClass('title')}>
                {title}
            </div>
            <IconButton className={getChildClass('close')} size="xs" onClick={onClose} onKeyDown={onCloseKeyDown}>
                <Close style={{ height: '1.5rem', width: '1.5rem' }}/>
            </IconButton>
        </div>);
};
export default ModalHeader;
