import React from 'react';
import styles from './LocaleButton.module.scss';
import getClassName from '@utils/getClassName';
import { useThemeContext } from '@context/ThemeProvider';
const LocaleButton = ({ className, handleLanguageChange, locale, selected }) => {
    const { themeColor } = useThemeContext();
    const [rootClass] = getClassName({
        styles,
        rootClass: 'localeButton',
        modifiers: {
            selected,
            [themeColor]: !!themeColor,
        },
        className,
    });
    return locale ? (<div className={rootClass} onClick={() => handleLanguageChange(locale)} aria-label={locale?.translatedName}>
            {locale?.translatedName} ({locale?.cultureRegionCode})
        </div>) : null;
};
export default LocaleButton;
