import React from 'react';
import getClassName from '@utils/getClassName';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import LoadingIcon from '@icons/Loading';
// import EmblemIcon from '@icons/Emblem';
import UpdatedLoadingIcon from '@icons/UpdatedLoading';
import styles from './LoadingSpinner.module.scss';
const LoadingSpinner = ({ className, logoType = 'oldLogo', ...props }) => {
    const [rootClass, getChildClass] = getClassName({
        rootClass: 'loading-spinner',
        styles,
    });
    return (<div className={rootClass}>
            <UpdatedLoadingIcon className={getChildClass('spinner')} {...props}/>
        </div>);
};
LoadingSpinner.displayName = 'LoadingSpinner';
export default LoadingSpinner;
