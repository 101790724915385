import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import keyCodes from '../core-utils/keyCodes';
import getClassName from '@utils/getClassName';
import styles from './AlertModal.module.scss';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalSubHeader from './ModalSubHeader';
const AlertModal = ({ actions, className, children, isOpen, title, message, subHeader, size, role, onClose, onClosed, onExit, }) => {
    const labelledById = `modal_header`;
    const describedById = `modal_body`;
    const setDescribedByAttribute = (ref) => {
        if (ref && role === 'alertdialog') {
            ref.setAttribute('aria-describedby', describedById);
        }
    };
    const handleCloseKeyDown = (e) => {
        if (e.keyCode === keyCodes.ENTER_KEY) {
            e.preventDefault();
            onClose?.();
        }
    };
    const [rootClasses, getChildClass] = getClassName({
        rootClass: 'alert-modal',
        className,
        styles,
        modifiers: {
            [size]: !!size,
        },
    });
    return (<>
            {isOpen && <div className={getChildClass('overlay')}> </div>}
            <Modal role={role} className={rootClasses} isOpen={isOpen} toggle={onClose} onClosed={onClosed} onExit={onExit} centered zIndex="auto" backdrop={'static'} size={size} labelledBy={labelledById} innerRef={setDescribedByAttribute}>
                <ModalHeader id={labelledById} title={title} onClose={onClose} onCloseKeyDown={handleCloseKeyDown}/>
                {subHeader && <ModalSubHeader className={getChildClass('subheader')}>{subHeader}</ModalSubHeader>}
                <ModalBody id={describedById} className={getChildClass('body')}>
                    {message || children}
                </ModalBody>
                <ModalFooter className={getChildClass('footer')}>{actions}</ModalFooter>
            </Modal>
        </>);
};
export default AlertModal;
