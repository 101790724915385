import React, { useEffect, useState } from 'react';
import { Sticky, Alert } from 'onsolve-ui-components';
import { useHistory } from 'react-router-dom';
import { bannerObserver } from './BannerObserver';
import classNames from 'classnames';
import styles from './Banner.module.scss';
const authorizationError = 'Request is not authorized.';
export const Banner = () => {
    const [banner, setBanner] = useState(null);
    const history = useHistory();
    useEffect(() => {
        if (banner?.messages.includes(authorizationError)) {
            history.push('/denied');
        }
        bannerObserver.subscribe(setBanner);
        return () => {
            bannerObserver.unsubscribe(setBanner);
        };
    }, [setBanner, banner, history]);
    const handleClose = (index) => () => {
        if (!banner?.messages.length) {
            setBanner(null);
            return;
        }
        setBanner({
            type: banner.type,
            messages: banner.messages.filter((message, messageIndex) => {
                return messageIndex !== index;
            }),
        });
    };
    const handleOnClick = () => {
        banner?.hookRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };
    if (!banner) {
        return null;
    }
    return (<Sticky className={classNames({ [styles.cursorPointer]: banner?.hookRef })} disabled={false} placement="top" scrollTarget="#layout-page-scroll" onClick={handleOnClick}>
            {banner.messages.map((message, index) => {
            return (<Alert color={banner.type} isOpen onClose={handleClose(index)} key={message + index} closeTimeout={banner.closeTimeout}>
                        {message}
                    </Alert>);
        })}
        </Sticky>);
};
