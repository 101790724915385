import React from 'react';
import { Button, BlurWrapper } from 'onsolve-ui-components';
import { t } from '@lingui/macro';
import styles from './RegistrationSuccessful.module.scss';
import { RegistrationSuccessImage } from './RegistrationSuccessImage';
import configService from '@services/config.service';
import { createIdentityManager, onSignin } from '@services/identityManager';
import SessionStorageUtility from '@utility/sessionStorageUtility';
import { identityRoutes } from '@routes/identityRoutes';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const RegistrationSuccessful = ({ isLoading, portalUrl }) => {
    const { IdentityServerBaseUrl = '', IdentityServerRedirectUrl = '', IdentityServerClientId = '', IdentityServerClientScope = '', SilentAuthorization = false, SessionMonitoring = false, SelfRegistrationBaseUrl = '', } = configService || {};
    const portalConfig = SessionStorageUtility.getPortalConfiguration();
    const identityManager = createIdentityManager({
        identityServerBaseUrl: IdentityServerBaseUrl,
        identityServerRedirectUrl: IdentityServerRedirectUrl,
        clientId: IdentityServerClientId,
        scope: IdentityServerClientScope,
        enableSilentAuthorization: SilentAuthorization,
        enableSessionMonitoring: SessionMonitoring,
        origin: window.location.toString().replace('/registrationsuccessful', `/${portalUrl}`),
        organizationUuid: portalConfig?.organizationUuid,
        divisionUuid: portalConfig?.divisionUuid,
        portalId: portalConfig?.id,
        selfRegBaseUrl: SelfRegistrationBaseUrl,
        signinCallbackPath: identityRoutes.signinCallback,
        renewCallbackPath: identityRoutes.accessTokenRenewCallback,
        signoutCallbackPath: identityRoutes.signoutCallback,
        onSignin,
    });
    // This function simulates an incognito mode in the browser by clearing all memory
    const clearStorageAndRedirect = async () => {
        localStorage.clear();
        sessionStorage.clear();
        sessionStorage.setItem('newSession', 'true');
        document.cookie.split(';').forEach((c) => {
            document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
        });
        const databases = await window.indexedDB.databases();
        databases.forEach((db) => window.indexedDB.deleteDatabase(db.name));
        if (window.caches) {
            const cacheNames = await caches.keys();
            await Promise.all(cacheNames.map((name) => caches.delete(name)));
        }
        console.info('Browser storage and memory cleared!');
        // Perform OIDC logout. This is needed else the IDM treats the same session and tries to re-login with the old session.
        try {
            await identityManager.signoutRedirect();
        }
        catch (error) {
            console.error('Error during OIDC logout:', error);
        }
    };
    return (<div className={styles.container}>
            <h1 className={styles.heading_one}>
                <BlurWrapper isLoading={isLoading} height={2.6875} width={10}>
                    {t `Registration Processing!`}
                </BlurWrapper>
            </h1>

            <div className={styles.imgWrapper}>
                <RegistrationSuccessImage />
            </div>

            <p className={styles.text_large}>
                <BlurWrapper isLoading={isLoading} height={1.1875} width={30}>
                    {t `Your account has been registered and is being processed. This can take upto 30 minutes. If you would like to add further account information, please click on "View Profile".`}
                </BlurWrapper>
            </p>

            <div className={styles.buttonWrapper}>
                <BlurWrapper isLoading={isLoading} height={2.125} width={20}>
                    <Button className={styles.btnSecondary} to={portalUrl}>
                        {t `View Profile`}
                    </Button>
                </BlurWrapper>
                <BlurWrapper isLoading={isLoading} height={2.125} width={20}>
                    <Button className={styles.btnPrimary} color="primary" onClick={clearStorageAndRedirect}>
                        {t `Exit`}
                    </Button>
                </BlurWrapper>
            </div>
        </div>);
};
export default RegistrationSuccessful;
