import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import getApiClient from '@utils/getApiClient';
import { i18n } from '@lingui/core';
import { createCompiledCatalog } from './translations.compile';
import * as plural from 'make-plural/plurals';
import configService from '@services/config.service';
import CookieService from '@services/cookie.service';
import namespaceService from '@services/namespace.service';
const client = getApiClient({ baseURL: configService.TahoeUrl });
const getIsoCode = ({ isoCode }, withUnderscore) => {
    const code = isoCode === 'ar-AE' ? 'ar-WW' : isoCode;
    return withUnderscore ? code.replace('-', '_') : code;
};
const getBrowserPreferredLanguage = (localeData) => {
    if (!localeData) {
        return DEFAULT_CONTEXT.selectedLanguage;
    }
    const cookieService = new CookieService();
    const cookieIsoCode = cookieService.getCookieValue('lang');
    if (cookieIsoCode) {
        const cookieLanguage = localeData.find((locale) => {
            return (locale.lcId === cookieIsoCode ||
                locale.isoCode === cookieIsoCode ||
                locale.cultureLanguageCode === cookieIsoCode);
        });
        if (cookieLanguage) {
            return cookieLanguage;
        }
    }
    const isoCode = window.navigator.languages
        ? window.navigator.languages[0]
        : window.navigator.language || window.navigator['browserLanguage'] || window.navigator['userLanguage'];
    const browserLanguage = localeData.find((locale) => {
        return locale.isoCode === isoCode || locale.cultureLanguageCode === isoCode;
    });
    return browserLanguage || DEFAULT_CONTEXT.selectedLanguage;
};
const i18nSetCookie = (ui_lang, name = 'lang') => {
    if (ui_lang) {
        const cookieOptions = { 'max-age': 120 };
        if (process.env.NODE_ENV !== 'development') {
            //Do not set a cookie "domain" if running on localhost
            cookieOptions.domain = '.' + namespaceService.getDomainWithoutNamespace();
        }
        const cookieService = new CookieService();
        cookieService.setCookie(name, ui_lang, cookieOptions);
    }
};
const DEFAULT_SELECTED_LANGUAGE = {
    cultureDisplayName: 'English (United States)',
    cultureLanguageCode: 'en',
    cultureRegionCode: 'US',
    direction: 'LTR',
    isoCode: 'en-US',
    lcId: 'en_US',
    name: 'English',
    translatedName: 'English',
};
const DEFAULT_CONTEXT = {
    localeIsLoading: false,
    localeError: null,
    localeData: [DEFAULT_SELECTED_LANGUAGE],
    selectedLanguage: DEFAULT_SELECTED_LANGUAGE,
    setLanguage: () => null,
    translationsError: null,
    translationsIsLoading: false,
    translations: {},
};
const LanguageServiceContext = createContext(DEFAULT_CONTEXT);
export const useLanguageServiceContext = () => useContext(LanguageServiceContext);
const LanguageServiceProvider = ({ children }) => {
    const initialLanguage = getBrowserPreferredLanguage(DEFAULT_CONTEXT.localeData);
    const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage);
    const [localeDataLoaded, setLocaleDataLoaded] = useState(false);
    const [translations, setTranslations] = useState(DEFAULT_CONTEXT.translations);
    const { isLoading: localeIsLoading, error: localeError, data: localeData, } = useQuery(['localeData'], async () => {
        const { data } = await client.get('/res/locales.json');
        setLocaleDataLoaded(true);
        return data;
    }, {
        placeholderData: DEFAULT_CONTEXT.localeData,
        staleTime: Infinity,
        onError: (error) => {
            console.error('GET_LOCALE_ERROR', error);
        },
    });
    const { error: translationsError, isLoading: translationsIsLoading } = useQuery(['translationsData', selectedLanguage], async () => {
        const { data = DEFAULT_CONTEXT.localeData } = await client.get(`/actions/verbiageLookup/${getIsoCode(selectedLanguage, true)}`);
        return data;
    }, {
        enabled: !localeIsLoading,
        staleTime: 0,
        onError: (error) => {
            console.error('GET_TRANSLATIONS_ERROR', error);
        },
        onSuccess: async (translations) => {
            // TODO: Do whatever is needed when translations are loaded
            const compiledTranslations = {};
            try {
                const language = getIsoCode(selectedLanguage);
                const { messages } = await import(`../../locales/${language}/messages`);
                const compiledTranslations = eval('(' + createCompiledCatalog(translations.data, { strict: true }) + ')');
                i18n.load(language, messages);
                i18n.loadLocaleData({
                    [language]: { plurals: plural[language.slice(0, 2).toLowerCase()] },
                });
                i18n.load(language, compiledTranslations);
                i18n.activate(language);
                setTranslations(compiledTranslations);
                // setMFEI18n({ ...selectedLanguage, translations: compiledTranslations });
            }
            catch (error) {
                console.error('SETMFEI18N_ERROR', error);
                setTranslations(compiledTranslations);
            }
        },
    });
    const setLanguage = (locale, setCookie = true) => {
        setSelectedLanguage(locale);
        setCookie && i18nSetCookie(locale.lcId);
    };
    useEffect(() => {
        if (localeDataLoaded && localeData.length >= 2) {
            const prefBrowserLanguage = getBrowserPreferredLanguage(localeData);
            setLanguage(prefBrowserLanguage, false);
        }
    }, [localeData, localeDataLoaded]);
    const values = {
        localeIsLoading,
        localeError,
        localeData,
        selectedLanguage,
        setLanguage,
        translationsError,
        translationsIsLoading,
        translations,
    };
    return <LanguageServiceContext.Provider value={values}>{children}</LanguageServiceContext.Provider>;
};
export default LanguageServiceProvider;
