import React from 'react';
import styles from './Button.module.scss';
import { useThemeContext } from '@context/ThemeProvider';
import getClassName from '@utils/getClassName';
const Button = ({ className, variant = 'contained', inverted = false, ...buttonProps }) => {
    const { themeColor } = useThemeContext();
    const [rootClass] = getClassName({
        rootClass: 'button',
        modifiers: {
            [themeColor]: !!themeColor && variant !== 'text' && !inverted,
            [variant]: !!variant && variant !== 'contained',
            [`${themeColor}--inverted`]: !!inverted,
        },
        styles,
    });
    return <button {...buttonProps} className={rootClass}/>;
};
export default Button;
