import React from 'react';
export const RegistrationSuccessImage = () => {
    return (<>
            <svg width="358" height="236" viewBox="0 0 428 306" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_986_4802)">
                    <path d="M19.912 62.1782C23.6759 62.1782 26.7273 59.1269 26.7273 55.3629C26.7273 51.5989 23.6759 48.5476 19.912 48.5476C16.148 48.5476 13.0967 51.5989 13.0967 55.3629C13.0967 59.1269 16.148 62.1782 19.912 62.1782Z" fill="#E6E6E6"/>
                    <path d="M40.3587 62.1782C44.1227 62.1782 47.174 59.1269 47.174 55.3629C47.174 51.5989 44.1227 48.5476 40.3587 48.5476C36.5948 48.5476 33.5435 51.5989 33.5435 55.3629C33.5435 59.1269 36.5948 62.1782 40.3587 62.1782Z" fill="#E6E6E6"/>
                    <path d="M60.8055 62.1782C64.5695 62.1782 67.6208 59.1269 67.6208 55.3629C67.6208 51.5989 64.5695 48.5476 60.8055 48.5476C57.0415 48.5476 53.9902 51.5989 53.9902 55.3629C53.9902 59.1269 57.0415 62.1782 60.8055 62.1782Z" fill="#E6E6E6"/>
                    <path d="M81.2499 62.1782C85.0138 62.1782 88.0651 59.1269 88.0651 55.3629C88.0651 51.5989 85.0138 48.5476 81.2499 48.5476C77.4859 48.5476 74.4346 51.5989 74.4346 55.3629C74.4346 59.1269 77.4859 62.1782 81.2499 62.1782Z" fill="#E6E6E6"/>
                    <path d="M101.697 62.1782C105.461 62.1782 108.512 59.1269 108.512 55.3629C108.512 51.5989 105.461 48.5476 101.697 48.5476C97.9327 48.5476 94.8813 51.5989 94.8813 55.3629C94.8813 59.1269 97.9327 62.1782 101.697 62.1782Z" fill="#E6E6E6"/>
                    <path d="M122.141 62.1782C125.905 62.1782 128.956 59.1269 128.956 55.3629C128.956 51.5989 125.905 48.5476 122.141 48.5476C118.377 48.5476 115.326 51.5989 115.326 55.3629C115.326 59.1269 118.377 62.1782 122.141 62.1782Z" fill="#E6E6E6"/>
                    <path d="M142.588 62.1782C146.352 62.1782 149.403 59.1269 149.403 55.3629C149.403 51.5989 146.352 48.5476 142.588 48.5476C138.824 48.5476 135.772 51.5989 135.772 55.3629C135.772 59.1269 138.824 62.1782 142.588 62.1782Z" fill="#E6E6E6"/>
                    <path d="M158.336 63.6625H4.16311C3.42781 63.6617 2.72287 63.3692 2.20293 62.8492C1.68299 62.3293 1.39051 61.6244 1.38965 60.8891V49.8372C1.39051 49.1019 1.68299 48.3969 2.20293 47.877C2.72287 47.3571 3.42781 47.0646 4.16311 47.0637H158.336C159.071 47.0646 159.776 47.3571 160.296 47.877C160.816 48.3969 161.108 49.1019 161.109 49.8372V60.8891C161.108 61.6244 160.816 62.3293 160.296 62.8492C159.776 63.3692 159.071 63.6617 158.336 63.6625ZM4.16311 48.1731C3.72192 48.1736 3.29893 48.3491 2.98696 48.661C2.67499 48.973 2.49951 49.396 2.49903 49.8372V60.8891C2.49951 61.3303 2.67499 61.7532 2.98696 62.0652C3.29893 62.3772 3.72192 62.5527 4.16311 62.5531H158.336C158.777 62.5527 159.2 62.3772 159.512 62.0652C159.824 61.7532 159.999 61.3303 160 60.8891V49.8372C159.999 49.396 159.824 48.973 159.512 48.661C159.2 48.3491 158.777 48.1736 158.336 48.1731L4.16311 48.1731Z" fill="#E6E6E6"/>
                    <path d="M37.1581 1.59352C37.5805 1.594 37.9856 1.76205 38.2843 2.06078C38.583 2.35952 38.7511 2.76455 38.7516 3.18702V37.7183C38.7511 38.1408 38.583 38.5458 38.2843 38.8446C37.9856 39.1433 37.5805 39.3113 37.1581 39.3118H3.33208C2.9096 39.3113 2.50457 39.1433 2.20583 38.8446C1.9071 38.5458 1.73905 38.1408 1.73857 37.7183V3.18705C1.73905 2.76458 1.9071 2.35954 2.20583 2.06081C2.50457 1.76207 2.9096 1.59403 3.33208 1.59354L37.1581 1.59352ZM37.1581 2.28882e-05H3.33208C2.48682 2.28882e-05 1.67618 0.335799 1.07849 0.933487C0.480803 1.53117 0.14502 2.34181 0.14502 3.18707V37.7183C0.14502 38.5636 0.480803 39.3742 1.07849 39.9719C1.67618 40.5696 2.48682 40.9054 3.33208 40.9054H37.1581C38.0033 40.9054 38.814 40.5696 39.4117 39.9719C40.0093 39.3742 40.3451 38.5636 40.3451 37.7183V3.18705C40.3451 2.34179 40.0093 1.53115 39.4117 0.933464C38.814 0.335777 38.0033 2.42143e-05 37.1581 2.28882e-05Z" fill="#E6E6E6"/>
                    <path d="M31.8818 10.6702H9.31337C9.20833 10.6708 9.1042 10.6507 9.00697 10.6109C8.90973 10.5712 8.82132 10.5126 8.74682 10.4386C8.67231 10.3645 8.61319 10.2765 8.57284 10.1795C8.53249 10.0825 8.51172 9.97847 8.51172 9.87343C8.51172 9.76838 8.53249 9.66438 8.57284 9.56739C8.61319 9.4704 8.67231 9.38235 8.74682 9.3083C8.82132 9.23425 8.90973 9.17567 9.00697 9.13592C9.1042 9.09617 9.20833 9.07603 9.31337 9.07668H31.8818C31.9869 9.07603 32.091 9.09617 32.1882 9.13592C32.2855 9.17567 32.3739 9.23425 32.4484 9.3083C32.5229 9.38235 32.582 9.4704 32.6224 9.56739C32.6627 9.66438 32.6835 9.76838 32.6835 9.87343C32.6835 9.97847 32.6627 10.0825 32.6224 10.1795C32.582 10.2765 32.5229 10.3645 32.4484 10.4386C32.3739 10.5126 32.2855 10.5712 32.1882 10.6109C32.091 10.6507 31.9869 10.6708 31.8818 10.6702Z" fill="#FF7901"/>
                    <path d="M31.8818 16.3128H9.31337C9.20833 16.3134 9.1042 16.2933 9.00697 16.2535C8.90973 16.2138 8.82132 16.1552 8.74682 16.0811C8.67231 16.0071 8.61319 15.919 8.57284 15.822C8.53249 15.7251 8.51172 15.621 8.51172 15.516C8.51172 15.411 8.53249 15.3069 8.57284 15.21C8.61319 15.113 8.67231 15.0249 8.74682 14.9509C8.82132 14.8768 8.90973 14.8182 9.00697 14.7785C9.1042 14.7387 9.20833 14.7186 9.31337 14.7193H31.8818C31.9869 14.7186 32.091 14.7387 32.1882 14.7785C32.2855 14.8182 32.3739 14.8768 32.4484 14.9509C32.5229 15.0249 32.582 15.113 32.6224 15.21C32.6627 15.3069 32.6835 15.411 32.6835 15.516C32.6835 15.621 32.6627 15.7251 32.6224 15.822C32.582 15.919 32.5229 16.0071 32.4484 16.0811C32.3739 16.1552 32.2855 16.2138 32.1882 16.2535C32.091 16.2933 31.9869 16.3134 31.8818 16.3128Z" fill="#FF7901"/>
                    <path d="M31.8818 21.9544H9.31337C9.20833 21.955 9.1042 21.9349 9.00697 21.8951C8.90973 21.8554 8.82132 21.7968 8.74682 21.7227C8.67231 21.6487 8.61319 21.5606 8.57284 21.4636C8.53249 21.3667 8.51172 21.2626 8.51172 21.1576C8.51172 21.0526 8.53249 20.9485 8.57284 20.8516C8.61319 20.7546 8.67231 20.6665 8.74682 20.5925C8.82132 20.5184 8.90973 20.4598 9.00697 20.4201C9.1042 20.3803 9.20833 20.3602 9.31337 20.3609H31.8818C31.9869 20.3602 32.091 20.3803 32.1882 20.4201C32.2855 20.4598 32.3739 20.5184 32.4484 20.5925C32.5229 20.6665 32.582 20.7546 32.6224 20.8516C32.6627 20.9485 32.6835 21.0526 32.6835 21.1576C32.6835 21.2626 32.6627 21.3667 32.6224 21.4636C32.582 21.5606 32.5229 21.6487 32.4484 21.7227C32.3739 21.7968 32.2855 21.8554 32.1882 21.8951C32.091 21.9349 31.9869 21.955 31.8818 21.9544Z" fill="#FF7901"/>
                    <path d="M31.8818 27.5969H9.31337C9.20833 27.5976 9.1042 27.5774 9.00697 27.5377C8.90973 27.4979 8.82132 27.4394 8.74682 27.3653C8.67231 27.2913 8.61319 27.2032 8.57284 27.1062C8.53249 27.0092 8.51172 26.9052 8.51172 26.8002C8.51172 26.6951 8.53249 26.5911 8.57284 26.4941C8.61319 26.3972 8.67231 26.3091 8.74682 26.2351C8.82132 26.161 8.90973 26.1024 9.00697 26.0627C9.1042 26.0229 9.20833 26.0028 9.31337 26.0034H31.8818C31.9869 26.0028 32.091 26.0229 32.1882 26.0627C32.2855 26.1024 32.3739 26.161 32.4484 26.2351C32.5229 26.3091 32.582 26.3972 32.6224 26.4941C32.6627 26.5911 32.6835 26.6951 32.6835 26.8002C32.6835 26.9052 32.6627 27.0092 32.6224 27.1062C32.582 27.2032 32.5229 27.2913 32.4484 27.3653C32.3739 27.4394 32.2855 27.4979 32.1882 27.5377C32.091 27.5774 31.9869 27.5976 31.8818 27.5969Z" fill="#FF7901"/>
                    <path d="M31.8818 33.239H9.31336C9.10205 33.239 8.89938 33.1551 8.74996 33.0057C8.60054 32.8563 8.5166 32.6536 8.5166 32.4423C8.5166 32.231 8.60054 32.0283 8.74996 31.8789C8.89938 31.7295 9.10205 31.6455 9.31336 31.6455H31.8818C32.0931 31.6455 32.2958 31.7295 32.4452 31.8789C32.5946 32.0283 32.6786 32.231 32.6786 32.4423C32.6786 32.6536 32.5946 32.8563 32.4452 33.0057C32.2958 33.1551 32.0931 33.239 31.8818 33.239Z" fill="#FF7901"/>
                    <path d="M152.688 1.59352C153.11 1.594 153.515 1.76205 153.814 2.06078C154.113 2.35952 154.281 2.76455 154.281 3.18702V37.7183C154.281 38.1408 154.113 38.5458 153.814 38.8446C153.515 39.1433 153.11 39.3113 152.688 39.3118H118.862C118.439 39.3113 118.034 39.1433 117.736 38.8446C117.437 38.5458 117.269 38.1408 117.268 37.7183V3.18705C117.269 2.76458 117.437 2.35954 117.736 2.06081C118.034 1.76207 118.439 1.59403 118.862 1.59354L152.688 1.59352ZM152.688 2.28882e-05H118.862C118.017 2.28882e-05 117.206 0.335799 116.608 0.933487C116.011 1.53117 115.675 2.34181 115.675 3.18707V37.7183C115.675 38.5636 116.011 39.3742 116.608 39.9719C117.206 40.5696 118.017 40.9054 118.862 40.9054H152.688C153.533 40.9054 154.344 40.5696 154.941 39.9719C155.539 39.3742 155.875 38.5636 155.875 37.7183V3.18705C155.875 2.34179 155.539 1.53115 154.941 0.933464C154.344 0.335776 153.533 2.28882e-05 152.688 2.28882e-05Z" fill="#E6E6E6"/>
                    <path d="M147.412 10.6702H124.843C124.738 10.6708 124.634 10.6507 124.537 10.6109C124.44 10.5712 124.351 10.5126 124.277 10.4386C124.202 10.3645 124.143 10.2765 124.103 10.1795C124.062 10.0825 124.042 9.97847 124.042 9.87343C124.042 9.76838 124.062 9.66438 124.103 9.56739C124.143 9.4704 124.202 9.38235 124.277 9.3083C124.351 9.23425 124.44 9.17567 124.537 9.13592C124.634 9.09617 124.738 9.07603 124.843 9.07668H147.412C147.622 9.07797 147.823 9.16248 147.972 9.31176C148.12 9.46104 148.203 9.66296 148.203 9.87343C148.203 10.0839 148.12 10.2858 147.972 10.4351C147.823 10.5844 147.622 10.6689 147.412 10.6702Z" fill="#FF7901"/>
                    <path d="M147.412 16.3128H124.843C124.738 16.3134 124.634 16.2933 124.537 16.2535C124.44 16.2138 124.351 16.1552 124.277 16.0811C124.202 16.0071 124.143 15.919 124.103 15.822C124.062 15.7251 124.042 15.621 124.042 15.516C124.042 15.411 124.062 15.3069 124.103 15.21C124.143 15.113 124.202 15.0249 124.277 14.9509C124.351 14.8768 124.44 14.8182 124.537 14.7785C124.634 14.7387 124.738 14.7186 124.843 14.7193H147.412C147.622 14.7205 147.823 14.8051 147.972 14.9543C148.12 15.1036 148.203 15.3055 148.203 15.516C148.203 15.7265 148.12 15.9284 147.972 16.0777C147.823 16.2269 147.622 16.3115 147.412 16.3128Z" fill="#FF7901"/>
                    <path d="M147.412 21.9544H124.843C124.738 21.955 124.634 21.9349 124.537 21.8951C124.44 21.8554 124.351 21.7968 124.277 21.7227C124.202 21.6487 124.143 21.5606 124.103 21.4636C124.062 21.3667 124.042 21.2626 124.042 21.1576C124.042 21.0526 124.062 20.9485 124.103 20.8516C124.143 20.7546 124.202 20.6665 124.277 20.5925C124.351 20.5184 124.44 20.4598 124.537 20.4201C124.634 20.3803 124.738 20.3602 124.843 20.3609H147.412C147.622 20.3621 147.823 20.4467 147.972 20.5959C148.12 20.7452 148.203 20.9471 148.203 21.1576C148.203 21.3681 148.12 21.57 147.972 21.7193C147.823 21.8685 147.622 21.9531 147.412 21.9544Z" fill="#FF7901"/>
                    <path d="M147.412 27.5969H124.843C124.738 27.5976 124.634 27.5774 124.537 27.5377C124.44 27.4979 124.351 27.4394 124.277 27.3653C124.202 27.2913 124.143 27.2032 124.103 27.1062C124.062 27.0092 124.042 26.9052 124.042 26.8002C124.042 26.6951 124.062 26.5911 124.103 26.4941C124.143 26.3972 124.202 26.3091 124.277 26.2351C124.351 26.161 124.44 26.1024 124.537 26.0627C124.634 26.0229 124.738 26.0028 124.843 26.0034H147.412C147.622 26.0047 147.823 26.0892 147.972 26.2385C148.12 26.3878 148.203 26.5897 148.203 26.8002C148.203 27.0106 148.12 27.2126 147.972 27.3618C147.823 27.5111 147.622 27.5956 147.412 27.5969Z" fill="#FF7901"/>
                    <path d="M147.412 33.239H124.843C124.632 33.239 124.429 33.1551 124.28 33.0057C124.13 32.8563 124.046 32.6536 124.046 32.4423C124.046 32.231 124.13 32.0283 124.28 31.8789C124.429 31.7295 124.632 31.6455 124.843 31.6455H147.412C147.623 31.6455 147.826 31.7295 147.975 31.8789C148.124 32.0283 148.208 32.231 148.208 32.4423C148.208 32.6536 148.124 32.8563 147.975 33.0057C147.826 33.1551 147.623 33.239 147.412 33.239Z" fill="#FF7901"/>
                    <path d="M267.658 67.9895C268.081 67.99 268.486 68.1581 268.784 68.4568C269.083 68.7555 269.251 69.1605 269.252 69.583V104.114C269.251 104.537 269.083 104.942 268.784 105.241C268.486 105.539 268.081 105.707 267.658 105.708H233.832C233.41 105.707 233.005 105.539 232.706 105.241C232.407 104.942 232.239 104.537 232.239 104.114V69.5831C232.239 69.1606 232.407 68.7555 232.706 68.4568C233.005 68.1581 233.41 67.99 233.832 67.9895L267.658 67.9895ZM267.658 66.396H233.832C232.987 66.396 232.176 66.7318 231.578 67.3295C230.981 67.9272 230.645 68.7378 230.645 69.5831V104.114C230.645 104.96 230.981 105.77 231.578 106.368C232.176 106.966 232.987 107.301 233.832 107.301H267.658C268.503 107.301 269.314 106.966 269.912 106.368C270.509 105.77 270.845 104.96 270.845 104.114V69.5831C270.845 68.7378 270.509 67.9272 269.912 67.3295C269.314 66.7318 268.503 66.396 267.658 66.396Z" fill="#FF7901"/>
                    <path d="M262.381 77.0662H239.812C239.707 77.0668 239.603 77.0467 239.506 77.0069C239.409 76.9672 239.32 76.9086 239.246 76.8345C239.171 76.7605 239.112 76.6725 239.072 76.5755C239.032 76.4785 239.011 76.3745 239.011 76.2694C239.011 76.1644 239.032 76.0604 239.072 75.9634C239.112 75.8664 239.171 75.7783 239.246 75.7043C239.32 75.6303 239.409 75.5717 239.506 75.5319C239.603 75.4922 239.707 75.472 239.812 75.4727H262.381C262.591 75.474 262.793 75.5585 262.941 75.7078C263.089 75.857 263.173 76.059 263.173 76.2694C263.173 76.4799 263.089 76.6818 262.941 76.8311C262.793 76.9804 262.591 77.0649 262.381 77.0662Z" fill="#FF7901"/>
                    <path d="M262.382 82.7083H239.813C239.602 82.7083 239.399 82.6243 239.25 82.4749C239.101 82.3255 239.017 82.1228 239.017 81.9115C239.017 81.7002 239.101 81.4975 239.25 81.3481C239.399 81.1987 239.602 81.1147 239.813 81.1147H262.382C262.593 81.1147 262.796 81.1987 262.945 81.3481C263.095 81.4975 263.179 81.7002 263.179 81.9115C263.179 82.1228 263.095 82.3255 262.945 82.4749C262.796 82.6243 262.593 82.7083 262.382 82.7083Z" fill="#FF7901"/>
                    <path d="M262.381 88.3503H239.813C239.708 88.3509 239.604 88.3306 239.507 88.2908C239.41 88.251 239.321 88.1924 239.247 88.1184C239.173 88.0444 239.114 87.9563 239.073 87.8594C239.033 87.7625 239.012 87.6586 239.012 87.5536C239.012 87.4486 239.033 87.3447 239.073 87.2478C239.114 87.1508 239.173 87.0628 239.247 86.9888C239.321 86.9147 239.41 86.8561 239.507 86.8163C239.604 86.7765 239.708 86.7563 239.813 86.7568H262.381C262.592 86.7581 262.793 86.8426 262.942 86.9919C263.09 87.1412 263.173 87.3431 263.173 87.5536C263.173 87.7641 263.09 87.966 262.942 88.1153C262.793 88.2645 262.592 88.349 262.381 88.3503Z" fill="#FF7901"/>
                    <path d="M262.381 93.9924H239.812C239.707 93.9931 239.603 93.9729 239.506 93.9332C239.409 93.8934 239.32 93.8349 239.246 93.7608C239.171 93.6868 239.112 93.5987 239.072 93.5017C239.032 93.4047 239.011 93.3007 239.011 93.1957C239.011 93.0906 239.032 92.9866 239.072 92.8896C239.112 92.7927 239.171 92.7046 239.246 92.6306C239.32 92.5565 239.409 92.4979 239.506 92.4582C239.603 92.4184 239.707 92.3983 239.812 92.3989H262.381C262.591 92.4002 262.793 92.4847 262.941 92.634C263.089 92.7833 263.173 92.9852 263.173 93.1957C263.173 93.4061 263.089 93.6081 262.941 93.7574C262.793 93.9066 262.591 93.9911 262.381 93.9924Z" fill="#FF7901"/>
                    <path d="M262.381 99.6345H239.812C239.707 99.6352 239.603 99.615 239.506 99.5753C239.409 99.5355 239.32 99.477 239.246 99.4029C239.171 99.3289 239.112 99.2408 239.072 99.1438C239.032 99.0468 239.011 98.9428 239.011 98.8378C239.011 98.7327 239.032 98.6287 239.072 98.5317C239.112 98.4348 239.171 98.3467 239.246 98.2727C239.32 98.1986 239.409 98.14 239.506 98.1003C239.603 98.0605 239.707 98.0404 239.812 98.041H262.381C262.591 98.0423 262.793 98.1268 262.941 98.2761C263.089 98.4254 263.173 98.6273 263.173 98.8378C263.173 99.0483 263.089 99.2502 262.941 99.3994C262.793 99.5487 262.591 99.6332 262.381 99.6345Z" fill="#FF7901"/>
                    <path d="M95.3652 40.905H60.6535C59.7148 40.9039 58.8148 40.5305 58.1511 39.8668C57.4873 39.203 57.1139 38.303 57.1128 37.3643V12.5262C57.1138 11.5874 57.4872 10.6874 58.151 10.0235C58.8147 9.35968 59.7147 8.98623 60.6535 8.98511H95.3652C96.304 8.98614 97.2041 9.35955 97.868 10.0234C98.5318 10.6873 98.9052 11.5873 98.9063 12.5262V37.3643C98.9052 38.3031 98.5317 39.2031 97.8678 39.8669C97.204 40.5307 96.304 40.904 95.3652 40.905ZM60.6535 10.5786C60.1372 10.5793 59.6422 10.7847 59.2772 11.1498C58.9122 11.5149 58.7069 12.0099 58.7063 12.5262V37.3643C58.7069 37.8806 58.9122 38.3755 59.2773 38.7406C59.6423 39.1056 60.1372 39.3109 60.6535 39.3115H95.3652C95.8815 39.311 96.3765 39.1057 96.7416 38.7406C97.1067 38.3756 97.3121 37.8806 97.3127 37.3643V12.5262C97.3121 12.0099 97.1067 11.5149 96.7416 11.1497C96.3765 10.7846 95.8815 10.5793 95.3652 10.5786H60.6535Z" fill="#E6E6E6"/>
                    <path d="M89.2939 20.0996H66.7255C66.515 20.0983 66.3136 20.0138 66.1652 19.8645C66.0169 19.7153 65.9336 19.5133 65.9336 19.3029C65.9336 19.0924 66.0169 18.8905 66.1652 18.7412C66.3136 18.5919 66.515 18.5074 66.7255 18.5061H89.2939C89.399 18.5055 89.5031 18.5256 89.6004 18.5654C89.6976 18.6051 89.786 18.6637 89.8605 18.7377C89.935 18.8118 89.9941 18.8998 90.0345 18.9968C90.0748 19.0938 90.0956 19.1978 90.0956 19.3029C90.0956 19.4079 90.0748 19.5119 90.0345 19.6089C89.9941 19.7059 89.935 19.7939 89.8605 19.868C89.786 19.942 89.6976 20.0006 89.6004 20.0404C89.5031 20.0801 89.399 20.1003 89.2939 20.0996Z" fill="#FF7901"/>
                    <path d="M89.2939 25.7422H66.7255C66.515 25.7409 66.3136 25.6564 66.1652 25.5071C66.0169 25.3578 65.9336 25.1559 65.9336 24.9454C65.9336 24.735 66.0169 24.5331 66.1652 24.3838C66.3136 24.2345 66.515 24.15 66.7255 24.1487H89.2939C89.399 24.1481 89.5031 24.1682 89.6004 24.2079C89.6976 24.2477 89.786 24.3063 89.8605 24.3803C89.935 24.4544 89.9941 24.5424 90.0345 24.6394C90.0748 24.7364 90.0956 24.8404 90.0956 24.9454C90.0956 25.0505 90.0748 25.1545 90.0345 25.2515C89.9941 25.3485 89.935 25.4365 89.8605 25.5106C89.786 25.5846 89.6976 25.6432 89.6004 25.683C89.5031 25.7227 89.399 25.7428 89.2939 25.7422Z" fill="#FF7901"/>
                    <path d="M89.2939 31.3838H66.7255C66.515 31.3825 66.3136 31.298 66.1652 31.1487C66.0169 30.9994 65.9336 30.7975 65.9336 30.587C65.9336 30.3766 66.0169 30.1747 66.1652 30.0254C66.3136 29.8761 66.515 29.7916 66.7255 29.7903H89.2939C89.399 29.7897 89.5031 29.8098 89.6004 29.8495C89.6976 29.8893 89.786 29.9479 89.8605 30.0219C89.935 30.096 89.9941 30.184 90.0345 30.281C90.0748 30.378 90.0956 30.482 90.0956 30.587C90.0956 30.6921 90.0748 30.7961 90.0345 30.8931C89.9941 30.9901 89.935 31.0781 89.8605 31.1522C89.786 31.2262 89.6976 31.2848 89.6004 31.3246C89.5031 31.3643 89.399 31.3844 89.2939 31.3838Z" fill="#FF7901"/>
                    <path d="M210.546 54.6688L177.208 45.0008C176.307 44.7382 175.546 44.129 175.094 43.3066C174.641 42.4842 174.533 41.5159 174.793 40.614L181.711 16.7587C181.974 15.8574 182.583 15.0969 183.406 14.6442C184.228 14.1915 185.196 14.0835 186.098 14.3439L219.436 24.012C220.338 24.2745 221.098 24.8838 221.551 25.7063C222.004 26.5288 222.112 27.4973 221.851 28.3992L214.933 52.2545C214.671 53.1558 214.061 53.9162 213.239 54.3688C212.416 54.8214 211.448 54.9293 210.546 54.6688ZM185.654 15.8744C185.158 15.7312 184.626 15.7906 184.174 16.0396C183.721 16.2886 183.386 16.7068 183.242 17.2026L176.324 41.0578C176.181 41.5538 176.24 42.0864 176.489 42.5386C176.738 42.9909 177.156 43.326 177.652 43.4703L210.99 53.1384C211.486 53.2816 212.018 53.2223 212.471 52.9734C212.923 52.7245 213.258 52.3064 213.403 51.8107L220.321 27.9554C220.464 27.4593 220.404 26.9267 220.156 26.4744C219.907 26.022 219.488 25.6869 218.993 25.5425L185.654 15.8744Z" fill="#E6E6E6"/>
                    <path d="M210.509 32.9959L188.834 26.71C188.631 26.6508 188.46 26.5137 188.359 26.3287C188.257 26.1437 188.233 25.926 188.292 25.7233C188.351 25.5206 188.487 25.3495 188.672 25.2476C188.857 25.1456 189.075 25.1212 189.277 25.1795L210.953 31.4654C211.156 31.5243 211.327 31.6613 211.429 31.8465C211.531 32.0316 211.555 32.2496 211.496 32.4526C211.437 32.6555 211.3 32.8268 211.115 32.9287C210.93 33.0306 210.712 33.0547 210.509 32.9959Z" fill="#FF7901"/>
                    <path d="M208.937 38.4145L187.261 32.1286C187.161 32.0995 187.067 32.0508 186.985 31.9855C186.904 31.9201 186.836 31.8393 186.785 31.7476C186.735 31.6559 186.703 31.5552 186.691 31.4512C186.68 31.3472 186.689 31.242 186.718 31.1415C186.747 31.041 186.796 30.9472 186.861 30.8655C186.927 30.7839 187.007 30.7158 187.099 30.6654C187.191 30.6149 187.291 30.583 187.395 30.5715C187.499 30.56 187.605 30.569 187.705 30.5982L209.381 36.8841C209.583 36.9432 209.754 37.0804 209.856 37.2654C209.957 37.4503 209.981 37.6681 209.922 37.8708C209.864 38.0735 209.727 38.2445 209.542 38.3465C209.357 38.4484 209.14 38.4729 208.937 38.4145Z" fill="#FF7901"/>
                    <path d="M207.364 43.8338L185.689 37.5479C185.486 37.4887 185.316 37.3516 185.214 37.1666C185.113 36.9816 185.088 36.7639 185.147 36.5612C185.206 36.3585 185.343 36.1874 185.528 36.0855C185.712 35.9835 185.93 35.959 186.133 36.0174L207.808 42.3033C208.011 42.3622 208.183 42.4992 208.284 42.6844C208.386 42.8695 208.41 43.0875 208.352 43.2905C208.293 43.4934 208.156 43.6647 207.971 43.7666C207.785 43.8684 207.567 43.8926 207.364 43.8338Z" fill="#FF7901"/>
                    <path d="M427.853 303.888H78.1968V305.388H427.853V303.888Z" fill="#E6E6E6"/>
                    <path d="M281.214 301.543H224.354L226.282 276.487H279.286L281.214 301.543Z" fill="#E6E6E6"/>
                    <path d="M285.069 298.652H221.463V304.434H285.069V298.652Z" fill="#E6E6E6"/>
                    <path d="M361.685 115.064H144.367C142.834 115.064 141.363 115.673 140.279 116.757C139.194 117.842 138.585 119.312 138.585 120.846V253.357H367.467V120.846C367.467 120.087 367.318 119.335 367.027 118.633C366.737 117.932 366.311 117.294 365.774 116.757C365.237 116.22 364.599 115.794 363.898 115.504C363.196 115.213 362.444 115.064 361.685 115.064Z" fill="#3F3D56"/>
                    <path d="M138.585 253.357V275.041C138.585 275.8 138.735 276.552 139.025 277.253C139.316 277.955 139.742 278.592 140.279 279.129C140.815 279.666 141.453 280.092 142.154 280.383C142.856 280.673 143.608 280.823 144.367 280.823H361.685C363.219 280.823 364.689 280.214 365.774 279.129C366.858 278.045 367.467 276.574 367.467 275.041V253.357H138.585Z" fill="#E6E6E6"/>
                    <path d="M356.384 127.11H150.149V242.756H356.384V127.11Z" fill="#E6E6E6"/>
                    <path d="M253.266 272.632C256.46 272.632 259.048 270.043 259.048 266.849C259.048 263.656 256.46 261.067 253.266 261.067C250.073 261.067 247.484 263.656 247.484 266.849C247.484 270.043 250.073 272.632 253.266 272.632Z" fill="#3F3D56"/>
                    <path d="M156.579 131.995C157.185 131.995 157.677 131.503 157.677 130.897C157.677 130.291 157.185 129.8 156.579 129.8C155.973 129.8 155.482 130.291 155.482 130.897C155.482 131.503 155.973 131.995 156.579 131.995Z" fill="white"/>
                    <path d="M160.744 131.995C161.35 131.995 161.842 131.503 161.842 130.897C161.842 130.291 161.35 129.8 160.744 129.8C160.138 129.8 159.647 130.291 159.647 130.897C159.647 131.503 160.138 131.995 160.744 131.995Z" fill="white"/>
                    <path d="M164.909 131.995C165.515 131.995 166.007 131.503 166.007 130.897C166.007 130.291 165.515 129.8 164.909 129.8C164.303 129.8 163.812 130.291 163.812 130.897C163.812 131.503 164.303 131.995 164.909 131.995Z" fill="white"/>
                    <path d="M350.44 134.626H155.482V231.75H350.44V134.626Z" fill="white"/>
                    <path d="M168.442 149.399C168.259 149.4 168.084 149.472 167.954 149.601C167.825 149.731 167.753 149.906 167.752 150.089V184.927C167.753 185.109 167.825 185.284 167.954 185.414C168.084 185.543 168.259 185.616 168.442 185.616H208.674C208.857 185.616 209.032 185.543 209.161 185.414C209.29 185.284 209.363 185.109 209.363 184.927V150.089C209.363 149.906 209.29 149.731 209.161 149.601C209.032 149.472 208.857 149.4 208.674 149.399H168.442Z" fill="#E6E6E6"/>
                    <path d="M233.04 149.512C232.857 149.512 232.682 149.584 232.553 149.714C232.423 149.843 232.351 150.018 232.351 150.201V184.927C232.351 185.11 232.423 185.285 232.553 185.414C232.682 185.543 232.857 185.616 233.04 185.616H273.144C273.327 185.616 273.502 185.543 273.631 185.414C273.76 185.285 273.833 185.109 273.833 184.927V150.201C273.833 150.018 273.76 149.843 273.631 149.714C273.502 149.585 273.327 149.512 273.144 149.512H233.04Z" fill="#E6E6E6"/>
                    <path d="M296.959 149.147C296.777 149.148 296.602 149.22 296.472 149.35C296.343 149.479 296.271 149.654 296.271 149.837V184.927C296.271 185.109 296.343 185.284 296.472 185.414C296.602 185.543 296.777 185.616 296.959 185.616H337.48C337.663 185.616 337.838 185.543 337.967 185.414C338.097 185.284 338.169 185.109 338.169 184.927V149.837C338.169 149.654 338.097 149.479 337.967 149.35C337.838 149.22 337.663 149.148 337.48 149.147H296.959Z" fill="#E6E6E6"/>
                    <path d="M169.177 202.86C168.8 202.861 168.438 203.012 168.171 203.279C167.905 203.546 167.755 203.908 167.755 204.285C167.755 204.663 167.905 205.025 168.171 205.292C168.438 205.559 168.8 205.709 169.177 205.71H207.003C207.381 205.709 207.743 205.559 208.009 205.292C208.276 205.025 208.426 204.663 208.426 204.285C208.426 203.908 208.276 203.546 208.009 203.279C207.743 203.012 207.381 202.861 207.003 202.86H169.177Z" fill="#E6E6E6"/>
                    <path d="M169.177 209.638C168.8 209.639 168.438 209.789 168.171 210.056C167.905 210.324 167.755 210.686 167.755 211.063C167.755 211.441 167.905 211.803 168.171 212.07C168.438 212.337 168.8 212.488 169.177 212.488H186.208C186.586 212.488 186.948 212.337 187.214 212.07C187.481 211.803 187.631 211.441 187.631 211.063C187.631 210.686 187.481 210.324 187.214 210.056C186.948 209.789 186.586 209.639 186.208 209.638H169.177Z" fill="#FF7901"/>
                    <path d="M234.47 202.86C234.092 202.861 233.73 203.012 233.464 203.279C233.197 203.546 233.047 203.908 233.047 204.285C233.047 204.663 233.197 205.025 233.464 205.292C233.73 205.559 234.092 205.709 234.47 205.71H272.296C272.673 205.709 273.035 205.559 273.302 205.292C273.568 205.025 273.718 204.663 273.718 204.285C273.718 203.908 273.568 203.546 273.302 203.279C273.035 203.012 272.673 202.861 272.296 202.86H234.47Z" fill="#E6E6E6"/>
                    <path d="M234.47 209.638C234.092 209.639 233.73 209.789 233.464 210.056C233.197 210.324 233.047 210.686 233.047 211.063C233.047 211.441 233.197 211.803 233.464 212.07C233.73 212.337 234.092 212.488 234.47 212.488H251.501C251.878 212.488 252.24 212.337 252.507 212.07C252.774 211.803 252.923 211.441 252.923 211.063C252.923 210.686 252.774 210.324 252.507 210.056C252.24 209.789 251.878 209.639 251.501 209.638H234.47Z" fill="#FF7901"/>
                    <path d="M298.128 202.86C297.751 202.861 297.389 203.012 297.122 203.279C296.856 203.546 296.706 203.908 296.706 204.285C296.706 204.663 296.856 205.025 297.122 205.292C297.389 205.559 297.751 205.709 298.128 205.71H335.955C336.332 205.709 336.694 205.559 336.96 205.292C337.227 205.025 337.377 204.663 337.377 204.285C337.377 203.908 337.227 203.546 336.96 203.279C336.694 203.012 336.332 202.861 335.955 202.86H298.128Z" fill="#E6E6E6"/>
                    <path d="M169.177 195.86C168.8 195.861 168.438 196.012 168.171 196.279C167.905 196.546 167.755 196.908 167.755 197.285C167.755 197.663 167.905 198.025 168.171 198.292C168.438 198.559 168.8 198.709 169.177 198.71H207.003C207.381 198.709 207.743 198.559 208.009 198.292C208.276 198.025 208.426 197.663 208.426 197.285C208.426 196.908 208.276 196.546 208.009 196.279C207.743 196.012 207.381 195.861 207.003 195.86H169.177Z" fill="#E6E6E6"/>
                    <path d="M234.47 195.86C234.092 195.861 233.73 196.012 233.464 196.279C233.197 196.546 233.047 196.908 233.047 197.285C233.047 197.663 233.197 198.025 233.464 198.292C233.73 198.559 234.092 198.709 234.47 198.71H272.296C272.673 198.709 273.035 198.559 273.302 198.292C273.568 198.025 273.718 197.663 273.718 197.285C273.718 196.908 273.568 196.546 273.302 196.279C273.035 196.012 272.673 195.861 272.296 195.86H234.47Z" fill="#E6E6E6"/>
                    <path d="M298.128 195.86C297.751 195.861 297.389 196.012 297.122 196.279C296.856 196.546 296.706 196.908 296.706 197.285C296.706 197.663 296.856 198.025 297.122 198.292C297.389 198.559 297.751 198.709 298.128 198.71H335.955C336.332 198.709 336.694 198.559 336.96 198.292C337.227 198.025 337.377 197.663 337.377 197.285C337.377 196.908 337.227 196.546 336.96 196.279C336.694 196.012 336.332 195.861 335.955 195.86H298.128Z" fill="#E6E6E6"/>
                    <path d="M298.128 209.638C297.751 209.639 297.389 209.789 297.123 210.056C296.856 210.324 296.706 210.686 296.706 211.063C296.706 211.441 296.856 211.803 297.123 212.07C297.389 212.337 297.751 212.488 298.128 212.488H315.159C315.537 212.488 315.898 212.337 316.165 212.07C316.432 211.803 316.582 211.441 316.582 211.063C316.582 210.686 316.432 210.324 316.165 210.056C315.898 209.789 315.537 209.639 315.159 209.638H298.128Z" fill="#FF7901"/>
                </g>
                <defs>
                    <clipPath id="clip0_986_4802">
                        <rect width="427.709" height="305.388" fill="white" transform="translate(0.145508)"/>
                    </clipPath>
                </defs>
            </svg>
        </>);
};
