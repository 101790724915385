import {
    FBA_customReports,
    customReports,
    editAdHoc,
    myReport,
    viewAudit,
    viewOrphanedReports,
    viewReport,
    viewReporting
} from 'common/constants/permissions';

import globalPermissions from '../globalPermissions';

const canViewAdHocReports = permissions =>
    !!permissions[editAdHoc] &&
    (!!permissions[myReport] || !!permissions[viewOrphanedReports] || !!permissions[viewReport]);

const canViewAlertHistory = permissions =>
    !!permissions[myReport] || !!permissions[viewOrphanedReports] || !!permissions[viewReport];

const canViewCustomReports = permissions => !!permissions[customReports] && !!permissions[FBA_customReports];

const canViewReporting = permissions => !!permissions[viewReporting];

const canViewUsage = permissions => globalPermissions.canOrganizationAdmin(permissions);

const canViewAuditTrail = permissions => !!permissions[viewAudit];

export default {
    canViewAdHocReports,
    canViewAlertHistory,
    canViewAuditTrail,
    canViewCustomReports,
    canViewReporting,
    canViewUsage
};
