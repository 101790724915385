import React from 'react';
import styles from './Header.module.scss';
import { useThemeContext } from '@context/ThemeProvider';
import getClassName from '@utils/getClassName';
import LanguageDropDown from '@components/LanguageDropDown/LanguageDropDown';
import Button from '@core/Button';
import { t } from '@lingui/macro';
import configService from '@services/config.service';
import { createIdentityManager, onSignin } from '@services/identityManager';
import SessionStorageUtility from '@utility/sessionStorageUtility';
import { identityRoutes } from '@routes/identityRoutes';
import { useLocation } from 'react-router';
import { useLingui } from '@lingui/react';
const Header = () => {
    const { themeTitle, themeColor } = useThemeContext();
    const [rootClass, getChildClass] = getClassName({
        rootClass: 'header',
        modifiers: {
            [themeColor]: !!themeColor,
        },
        styles,
    });
    const location = useLocation();
    const { IdentityServerBaseUrl = '', IdentityServerRedirectUrl = '', IdentityServerClientId = '', IdentityServerClientScope = '', SilentAuthorization = false, SessionMonitoring = false, SelfRegistrationBaseUrl = '', } = configService || {};
    //won't have portal config in session storage if it's a new tab to a non-private route
    const portalConfig = SessionStorageUtility.getPortalConfiguration();
    const { i18n } = useLingui();
    const identityManager = createIdentityManager({
        identityServerBaseUrl: IdentityServerBaseUrl,
        identityServerRedirectUrl: IdentityServerRedirectUrl,
        clientId: IdentityServerClientId,
        scope: IdentityServerClientScope,
        enableSilentAuthorization: SilentAuthorization,
        enableSessionMonitoring: SessionMonitoring,
        origin: window.location.toString().replace('/contact', ''),
        organizationUuid: portalConfig?.organizationUuid,
        divisionUuid: portalConfig?.divisionUuid,
        portalId: portalConfig?.id,
        selfRegBaseUrl: SelfRegistrationBaseUrl,
        signinCallbackPath: identityRoutes.signinCallback,
        renewCallbackPath: identityRoutes.accessTokenRenewCallback,
        signoutCallbackPath: identityRoutes.signoutCallback,
        onSignin,
    });
    return (<header className={rootClass}>
            <h1 className={getChildClass('h1')}>{themeTitle}</h1>
            <div className={getChildClass('right')}>
                <LanguageDropDown className={getChildClass('locale')}/>
                {location.pathname.toLocaleLowerCase().includes('/travelportal/') && (<Button inverted onClick={() => {
                identityManager.signoutRedirect();
            }}>
                        {t(i18n) `Sign Out`}
                    </Button>)}
            </div>
        </header>);
};
export default Header;
