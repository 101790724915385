import axios from 'axios';
import JwtService from '@services/jwt.service';
import { stringify } from 'qs';
import configService from '@services/config.service';
import { accessToken } from '@routes/identityRoutes';
const jwtService = new JwtService();
const apiAllowedList = ['/api/signin', '/res/locales[.]json', '/actions/verbiageLookup/.*'];
const regexAllowedList = new RegExp(`^(${apiAllowedList.join('|')})$`);
const GatewayUrl = configService?.ApiBaseUrl;
const getApiClient = ({ baseURL, headers, paramsSerializerConfig, params } = {
    baseURL: GatewayUrl,
    headers: {},
    paramsSerializerConfig: { arrayFormat: 'repeat' },
    params: {},
}) => {
    const handleRequestInterception = (config) => {
        const token = jwtService.getToken(accessToken);
        // TODO: handle refresh token before sending real request,
        // make a queue for all pending requests until refresh token done
        return !regexAllowedList.test(config.url) && token
            ? {
                ...config,
                headers: {
                    ...config.headers,
                    common: {
                        ...config.headers.common,
                        Authorization: `Bearer ${token}`,
                    },
                },
            }
            : config;
    };
    const createHttpClient = () => {
        const client = axios.create({ baseURL, headers });
        client.interceptors.request.use(handleRequestInterception);
        client.defaults.paramsSerializer = () => stringify(params, { ...paramsSerializerConfig, encode: false });
        client.defaults.headers['Content-Type'] = 'application/json';
        return client;
    };
    const client = createHttpClient();
    return client;
};
export default getApiClient;
