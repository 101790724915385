import configurationService from './config.service';
const namespaceService = {
    getNamespace: () => {
        let urlPattern;
        const { UrlPattern: configUrlPattern } = configurationService;
        if (!configUrlPattern) {
            return;
        }
        const host = window.location.hostname.toLowerCase();
        const customerPlaceholder = '{customer}';
        const wwwPrefix = 'www.';
        if (!configUrlPattern.startsWith(wwwPrefix)) {
            urlPattern = `${wwwPrefix}${configUrlPattern}`;
        }
        const urlPatternPieces = new Set(urlPattern.split('.').filter((x) => x !== customerPlaceholder));
        const actualUrlPieces = host.split('.');
        const customerNamespace = actualUrlPieces.find((x) => !urlPatternPieces.has(x));
        return customerNamespace;
    },
    getProtocol: () => {
        return window.location.host.toLowerCase().includes('localhost') ? window.location.protocol : 'https:';
    },
    getOriginWithNamespace: () => {
        return window.location.origin.toLowerCase();
    },
    isLocalhost: () => window.location.host.toLowerCase().includes('localhost'),
    getOriginWithoutNamespace: () => {
        const origin = window.location.origin.toLowerCase();
        const namespace = namespaceService.getNamespace();
        return namespace ? origin.replace(`${namespace}.`, '') : origin;
    },
    getClientOrigin: (href) => {
        if (href) {
            return new URL(href).origin + '/';
        }
    },
    getDomainWithoutNamespace: () => {
        const hostname = window.location.hostname.toLowerCase();
        const namespace = namespaceService.getNamespace();
        return namespace ? hostname.replace(`${namespace}.`, '') : hostname;
    },
    getExternalDomain: () => {
        const { ExternalDomainName } = configurationService;
        return ExternalDomainName || namespaceService.getDomainWithoutNamespace();
    },
    getCombinedNamespaceURL: (namespace) => {
        const protocol = `${window.location.protocol}//`;
        return `${protocol}${namespace}.${window.location.href.slice(protocol.length)}`;
    },
};
export default namespaceService;
