import React, { createContext, useContext } from 'react';
import { identityManager } from '@services/identityManager';
const IdentityContext = createContext(identityManager);
export const useIdentityContext = () => useContext(IdentityContext);
const IdentityProvider = ({ value = identityManager, children }) => {
    return (<IdentityContext.Provider value={value}>
            <>{children}</>
        </IdentityContext.Provider>);
};
export default IdentityProvider;
