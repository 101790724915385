import { Scrollbars } from 'onsolve-ui-components';
import React from 'react';
import { Banner } from '@components/Banner/Banner';
import styles from './layout.module.scss';
export const Layout = ({ children }) => {
    return (<div className={styles.pageWrapper}>
            <div className={styles.bodyWrapper}>
                <div id="layout-page-scroll" className={styles.contentScroll}>
                    <Scrollbars vertical>
                        <Banner />

                        <div className={styles.mainContainer}>{children}</div>
                    </Scrollbars>
                </div>
            </div>
        </div>);
};
