const { UrlPattern, ApiBaseUrl, ExternalDomainName, RiskIntelligenceApiUrl, TahoeUrl, Authority, IdentityServerBaseUrl, IdentityServerRedirectUrl, IdentityServerClientId, IdentityServerClientScope, SelfRegistrationBaseUrl, SilentAuthorization, SessionMonitoring, } = JSON.parse(localStorage.getItem('bridge_config')) || {};
const _targetConfig = {
    // Microfrontend
    UrlPattern,
    ApiBaseUrl,
    ExternalDomainName,
    RiskIntelligenceApiUrl,
    TahoeUrl,
    Authority,
    IdentityServerBaseUrl,
    IdentityServerRedirectUrl,
    IdentityServerClientId,
    IdentityServerClientScope,
    SelfRegistrationBaseUrl,
    SilentAuthorization,
    SessionMonitoring,
};
const createConfig = () => {
    for (const key in _targetConfig) {
        if (!_targetConfig[key]) {
            /* eslint-disable no-console */
            console.error(`Configuration settings do not contain '${key}' key`);
            _targetConfig[key] = '';
        }
    }
    return Object.freeze(_targetConfig);
};
export default createConfig();
