import React from 'react';
import { WysiwygEditor, convertJsonToWysiwygValue } from 'onsolve-ui-components';
import styles from './CustomHelpText.module.scss';
import DotsLoadingSpinner from '@corecomponents/Loading/DotsLoadingSpinner';
import getClassName from '@utils/getClassName';
import { useThemeContext } from '@context/ThemeProvider';
import { useCustomHelpTextContext } from '@context/CustomHelpTextProvider';
const CustomHelpText = () => {
    const { customHelpText, isCustomHelpTextLoading } = useCustomHelpTextContext();
    const { themeLogo } = useThemeContext();
    const [rootClass, getChildClass] = getClassName({
        styles,
        rootClass: 'helpLink',
    });
    const renderRichText = ({ textValue: { value } }) => {
        return value ? (<WysiwygEditor className={styles.richText} editorValue={convertJsonToWysiwygValue(value)} readOnly noBorder scrollable={false}/>) : null;
    };
    if (isCustomHelpTextLoading) {
        return <DotsLoadingSpinner className={getChildClass('spinner')}/>;
    }
    return (<div className={rootClass}>
            <div className={getChildClass('content')}>
                <div className={getChildClass('wrapper')} id="logo">
                    <img className={styles.logo} src={themeLogo} alt={'LOGO'}/>
                </div>
                <div className={getChildClass('helpText')}>{renderRichText(customHelpText)}</div>
            </div>
        </div>);
};
export default CustomHelpText;
