import React from 'react';
import PropTypes from 'prop-types';
import getClassName from '@utils/getClassName';
import DotsSpinnerIcon from '@icons/DotsSpinner';
import styles from './DotLoadingSpinner.module.scss';
const DotsLoadingSpinner = ({ className, ...props }) => {
    const [rootClass] = getClassName({ rootClass: 'dotsLoadingSpinner', styles, className });
    return (<div className={rootClass}>
            <DotsSpinnerIcon {...props}/>
        </div>);
};
DotsLoadingSpinner.displayName = 'DotsLoadingSpinner';
DotsLoadingSpinner.propTypes = {
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string,
};
export default DotsLoadingSpinner;
