import React from 'react';
import getClassName from '@utils/getClassName';
import styles from './PopoverBody.module.scss';
const PopoverBody = ({ children, className, noScroll = false }) => {
    const [rootClass] = getClassName({
        rootClass: 'popoverBody',
        className,
        modifiers: {
            noScroll,
        },
        styles,
    });
    return <div className={rootClass}>{children}</div>;
};
PopoverBody.displayName = 'PopoverBody';
export default PopoverBody;
