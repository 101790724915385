import React from 'react';
import { messages } from '@locales/en-US/messages';
import * as plural from 'make-plural/plurals';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
i18n.loadLocaleData({
    'en-US': { plurals: plural['en'] },
});
i18n.load('en-US', messages);
i18n.activate('en-US');
const LinguiProvider = ({ children }) => (<I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
        {children}
    </I18nProvider>);
export default LinguiProvider;
