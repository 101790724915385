import { bannerObserver } from './BannerObserver';
const coerceToArray = (singleOrArray) => {
    if (Array.isArray(singleOrArray)) {
        return singleOrArray;
    }
    else {
        return [singleOrArray];
    }
};
const combineMessages = (messages) => {
    const values = Object.values(messages);
    return values.reduce((acc, value) => {
        return [...acc, ...coerceToArray(value)];
    }, []);
};
const BANNER_COLORS = {
    PRIMARY: 'primary',
    SUCCESS: 'success',
    DANGER: 'danger',
};
const showPrimary = (messages, options) => {
    bannerObserver.broadcast({ messages, type: BANNER_COLORS.PRIMARY, ...options });
};
const showSuccess = (messages, options) => {
    bannerObserver.broadcast({ messages, type: BANNER_COLORS.SUCCESS, ...options });
};
const showDanger = (messages, options) => {
    bannerObserver.broadcast({ messages, type: BANNER_COLORS.DANGER, ...options });
};
const clear = () => {
    bannerObserver.broadcast(null);
};
export const BannerManager = {
    primary: (message, options) => {
        showPrimary(coerceToArray(message), options);
    },
    primaryOfHash: (messages, options) => {
        showPrimary(combineMessages(messages), options);
    },
    danger: (message, options) => {
        showDanger(coerceToArray(message), options);
    },
    dangerOfHash: (messages, options) => {
        showDanger(combineMessages(messages), options);
    },
    success: (message, options) => {
        showSuccess(coerceToArray(message), options);
    },
    clear,
};
