import React from 'react';
import styles from './IconButton.module.scss';
import getClassName from '@utils/getClassName';
const IconButton = ({ children, className, size = 'sm', ...otherProps }) => {
    const [rootClass] = getClassName({
        styles,
        rootClass: 'iconButton',
        className,
    });
    return (<button {...otherProps} className={rootClass}>
            {children}
        </button>);
};
export default IconButton;
