import { useIdentityContext } from '@context/IdentityContext';
import configService from '@services/config.service';
import { createIdentityManager, onSignin } from '@services/identityManager';
import JwtService from '@services/jwt.service';
import SessionStorageUtility from '../common/utility/sessionStorageUtility';
import LoadingOverlay from '@corecomponents/Loading/LoadingOverlay';
import React, { useCallback, useEffect, useState } from 'react';
import { Route } from 'react-router';
import { identityRoutes } from './identityRoutes';
import { notifyError } from '@utils/notifyError';
import errorRoutes from 'common/constants/routes';
export const PrivateRoute = ({ exact, path, children }) => {
    const { signinRedirect } = useIdentityContext();
    const [loading, setLoading] = useState(true);
    const isAuthenticated = !new JwtService().isTokenExpired();
    const [portalUrl] = window.location.pathname.split('/').filter(Boolean);
    const redirectToSignIn = useCallback(({ id, organizationUuid, divisionUuid }) => {
        const { IdentityServerBaseUrl = '', IdentityServerRedirectUrl = '', IdentityServerClientId = '', IdentityServerClientScope = '', SilentAuthorization = false, SessionMonitoring = false, SelfRegistrationBaseUrl = '', } = configService || {};
        const identityManager = createIdentityManager({
            identityServerBaseUrl: IdentityServerBaseUrl,
            identityServerRedirectUrl: IdentityServerRedirectUrl,
            clientId: IdentityServerClientId,
            scope: IdentityServerClientScope,
            enableSilentAuthorization: SilentAuthorization,
            enableSessionMonitoring: SessionMonitoring,
            origin: window.location.toString().replace('/contact', ''),
            organizationUuid: organizationUuid,
            divisionUuid: divisionUuid,
            portalId: id,
            selfRegBaseUrl: SelfRegistrationBaseUrl,
            signinCallbackPath: identityRoutes.signinCallback,
            renewCallbackPath: identityRoutes.accessTokenRenewCallback,
            signoutCallbackPath: identityRoutes.signoutCallback,
            onSignin,
        });
        identityManager.signinRedirect().catch((e) => {
            console.error('Error in Private Route', e);
            notifyError(e, 'There was an issue with signing in');
        });
    }, [signinRedirect]);
    useEffect(() => {
        const fetchUser = async () => {
            try {
                //always get the basic portal config even if user is already logged in to support being logged into multiple portals at the same time
                const data = await fetch(`${configService.SelfRegistrationBaseUrl}/api/basic/${portalUrl}`)
                    .then((res) => res.json())
                    .catch((error) => {
                    console.error('Error in Private Route ', error);
                    window.location.href = `${errorRoutes.PAGE_LOAD_ERROR}`;
                    setLoading(false);
                });
                SessionStorageUtility.setPortalConfiguration({
                    portalUrl,
                    ...data,
                });
                if (!isAuthenticated) {
                    // first time login or signup workflow
                    redirectToSignIn(data);
                }
            }
            catch (e) {
                console.error('Error in Private Route', e);
                window.location.pathname = `${errorRoutes.PAGE_LOAD_ERROR}`;
            }
            finally {
                setLoading(false);
            }
        };
        fetchUser();
    }, []);
    const isPortalConfiguration = SessionStorageUtility.getPortalConfiguration() != null;
    if (loading || !isAuthenticated || !isPortalConfiguration) {
        return <LoadingOverlay />;
    }
    return <Route key={path} path={path} exact={exact} render={() => children}/>;
};
