import React from 'react';
import { IntlProvider } from 'react-intl';
import { useLingui } from '@lingui/react';
const ReactIntlProvider = ({ children }) => {
    const { i18n } = useLingui();
    return (<IntlProvider locale={i18n.locale} defaultLocale="en-US">
            {children}
        </IntlProvider>);
};
export default ReactIntlProvider;
